import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Background from "../Components/Common/Background";
import { FaInstagram } from "react-icons/fa";
import { TfiEmail } from 'react-icons/tfi'
import ContactForm from "../Components/Common/ContactForm";
import data from '../Constants/TeamMemData'
import styles from '../assets/css/teamDetail.module.scss'

const Kaushil = 'https://mysocialtheory.com/assets/images/team/Kaushil_Main.png'
const KaushilHover = 'https://mysocialtheory.com/assets/images/team/Kaushil_Hover.png'
const Abhijeet = 'https://mysocialtheory.com/assets/images/team/Abhijeet_Main.png'
const AbhijeetHover = 'https://mysocialtheory.com/assets/images/team/Abhijeet_Hover.png'
const KLogo = 'https://mysocialtheory.com/assets/images/team/Kaushil_FC_Barcelona_(crest).png'
const ALogo = 'https://mysocialtheory.com/assets/images/team/Abhijeet_Real_Madrid_CF.png'
const DLogo = 'https://mysocialtheory.com/assets/images/team/Dolcie_Harry_Potter_Crest.png'
const Dolcie = 'https://mysocialtheory.com/assets/images/team/Dolcie_Main.png'
const DolcieHover = 'https://mysocialtheory.com/assets/images/team/Dolcie_Hover.png'
const s95 = 'https://mysocialtheory.com/assets/images/team/95.svg'
const s90 = 'https://mysocialtheory.com/assets/images/team/90.svg'
const s85 = 'https://mysocialtheory.com/assets/images/team/85.svg'
const s80 = 'https://mysocialtheory.com/assets/images/team/80.svg'


const TeamDetails = () => {
  const { name } = useParams()

  const [isImageHover, setIsImageHover] = useState({
    kaushil: false,
    abhijeet: false,
    dolcie: false,
  })

  const getImage = () => {
    return name === "kaushil-shah"
      ? isImageHover.kaushil ? KaushilHover : Kaushil
      : name === "abhijeet-shah"
        ? isImageHover.abhijeet ? AbhijeetHover : Abhijeet
        : isImageHover.dolcie ? DolcieHover : Dolcie
  }

  const handleImageHover = () => {
    if ('ontouchstart' in window) return
    else {
      name === "kaushil-shah"
        ? setIsImageHover(prevState => ({ ...prevState, kaushil: !prevState.kaushil }))
        : name === "abhijeet-shah"
          ? setIsImageHover(prevState => ({ ...prevState, abhijeet: !prevState.abhijeet }))
          : setIsImageHover(prevState => ({ ...prevState, dolcie: !prevState.dolcie }))
    }
  }

  const handleImageOut = () => {
    if ('ontouchstart' in window) return
    else {
      name === "kaushil-shah"
        ? setIsImageHover(prevState => ({ ...prevState, kaushil: !prevState.kaushil }))
        : name === "abhijeet-shah"
          ? setIsImageHover(prevState => ({ ...prevState, abhijeet: !prevState.abhijeet }))
          : setIsImageHover(prevState => ({ ...prevState, dolcie: !prevState.dolcie }))
    }
  }

  return (
    <Background backgroundColor="#0d0d0d">
      <div className="spacer">
        <section className="team_details_main">
          <div className="container">
            <div className="team_details_row">
              <div className="row mb-5 align-items-center">
                <div className="col-12 col-md-6 g-4 text-center">
                  <div className="team_profile" onMouseOver={handleImageHover} onMouseOut={handleImageOut}>
                    <img className="w-full h-auto" src={getImage()} alt={name} />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="team_details">
                    <div className="d-flex align-items-center justify-content-md-start justify-content-center gap-3">
                      <h4 className={styles.name}>
                        {data[name].name}
                      </h4>
                      <div style={{ maxWidth: name === "jasneet-kaur" && "50px" }} className={styles.name_logo}>
                        <img className="w-100 h-auto" src={name === "kaushil-shah" ? KLogo : name === "abhijeet-shah" ? ALogo : DLogo} alt="logo" />
                      </div>
                    </div>
                    <p className={`${styles.role} text-sm-start text-center`}>{data[name].role}</p>
                    <i className={styles.title}>
                      {data[name].title}
                    </i>
                    <ul className="team_social_icons">
                      <p className={styles.social_head}>Social Media</p>
                      <li>
                        <a target="_new" href={name === "kaushil-shah"
                          ? "https://www.instagram.com/kaushontheroad/"
                          : name === "abhijeet-shah"
                            ? "https://www.instagram.com/abhishah_10/"
                            : "https://www.instagram.com/dreamyrubik/"}>
                          <FaInstagram />
                        </a>
                      </li>
                      <li>
                        <Link>
                          <TfiEmail />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="team_member_allInfo">
                <h4>Who is {name === "jasneet-kaur" ? "she" : "he"}?</h4>
                <p>
                  {data[name].about1}
                </p>
                <p>
                  {data[name].about2}
                </p>
                <p>
                  {data[name].about3}
                </p>
                <p>
                  {data[name].about4}
                </p>
              </div>
              <div className="team_member_allInfo">
                <h4>Professional Skills</h4>
                <div className="team_member_skills">
                  {name === "kaushil-shah"
                    &&
                    <>
                      <div className={styles.s1}>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s90} alt="skillChart" />
                            <span className="percent">90%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Graphic Designs</p>
                        </div>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s95} alt="skillChart" />
                            <span className="percent">95%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Content Writing</p>
                        </div>
                      </div>
                      <div className={styles.s2}>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s85} alt="skillChart" />
                            <span className="percent">85%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Marketing Strategy</p>
                        </div>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s80} alt="skillChart" />
                            <span className="percent">80%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Web development</p>
                        </div>
                      </div>
                    </>}
                  {name === "abhijeet-shah"
                    && <>
                      <div className={styles.s1}>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s95} alt="skillChart" />
                            <span className="percent">95%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Social Media Marketing</p>
                        </div>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s90} alt="skillChart" />
                            <span className="percent">90%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Graphic Designs</p>
                        </div>
                      </div>
                      <div className={styles.s2}>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s85} alt="skillChart" />
                            <span className="percent">85%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Branding</p>
                        </div>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s85} alt="skillChart" />
                            <span className="percent">85%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">UI/UX</p>
                        </div>
                      </div>
                    </>}
                  {name === "jasneet-kaur"
                    && <>
                      <div className={styles.s1}>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s90} alt="skillChart" />
                            <span className="percent">90%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Graphic Designs</p>
                        </div>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s85} alt="skillChart" />
                            <span className="percent">85%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Content Ideation</p>
                        </div>
                      </div>
                      <div className={styles.s2}>
                        <div className="w-100 d-flex flex-column">
                          <div className="chartMain">
                            <img className="w-100 h-auto" src={s95} alt="skillChart" />
                            <span className="percent">95%</span>
                          </div>
                          <p className="d-flex align-items-center justify-content-center text-center">Market Research</p>
                        </div>
                        <div className="w-100 d-flex flex-column"></div>
                      </div>
                    </>}
                </div>
              </div>
              <div className={styles.foot_text}>
                <p>
                  {name === "kaushil-shah"
                    ? "Got a crazy idea or itching for a chat about your latest travel adventure or a Unique marketing campaign? Kaushil's your guy!"
                    : name === "abhijeet-shah"
                      ? "Wanna chat about the latest marketing trends or the best food joints in town? Hit up Abhijeet!"
                      : "Fancy a brainstorm over bites or a duet jam session? Get in touch with Jasneet!"
                  }
                </p>
              </div>
            </div>
          </div>
        </section>
        <ContactForm
          heading="Contact Me"
          text="Your email address will not be published. Required fields are marked *"
        />
      </div>
    </Background>
  );
};

export default TeamDetails;
