import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Translate3d from "../Constants/Translate3d";
import Background from "../Components/Common/Background";
import styles from "../assets/css/service.module.scss";

const border = 'https://mysocialtheory.com/assets/images/border-blue.svg'

gsap.registerPlugin(ScrollTrigger)

const doubleTick = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M12.5903 13.7589L14.0023 15.1709L22.4678 6.70533L23.8821 8.11954L14.0023 17.9993L7.63828 11.6353L9.05249 10.2211L11.1773 12.3459L12.5903 13.7589ZM12.592 10.9312L17.5446 5.97852L18.9549 7.3888L14.0023 12.3414L12.592 10.9312ZM9.76526 16.5863L8.35224 17.9993L1.98828 11.6353L3.40249 10.2211L4.81551 11.6342L4.81432 11.6353L9.76526 16.5863Z" fill="#006EDA" />
</svg>

function Service() {
  const mainRef = useRef(null)
  const bgImgRef = useRef(null)
  const navigate = useNavigate()

  useLayoutEffect(() => {
    let sections = gsap.utils.toArray(mainRef.current.children[0].children);
    let headerHeight = document.getElementsByTagName("nav")[0].offsetHeight + "px"
    const mm = gsap.matchMedia();
    let ctx = gsap.context(() => {
      mm.add(
        '(min-width: 992px)',
        () => {
          gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none",
            scrollTrigger: {
              trigger: mainRef.current.children,
              pin: mainRef.current,
              pinSpacing: true,
              scrub: 1,
              start: `top ${headerHeight}`,
              end: "+=6000",
            }
          });
          gsap.to(bgImgRef.current, {
            backgroundPosition: "69% 50%",
            ease: "none",
            scrollTrigger: {
              trigger: mainRef.current.children,
              start: `top ${headerHeight}`,
              end: "bottom top",
              scrub: 2,
            }
          });
        },
        mainRef
      )
    })

    return () => ctx.revert();
  }, [])

  useLayoutEffect(() => {
    Translate3d(document.querySelectorAll("#translate3d"))
  }, [])

  return (
    <Background backgroundColor="#0d0d0d">
      <div ref={mainRef} className={styles.main}>
        <div className={styles.horizontal_sliders}>
          <div style={{ minHeight: "100%" }} className={styles.panel}>
            <div className={styles.panel1}>
              <div className={styles.description}>
                <span>SYMPHONY OF SUCCESS:</span>
                <h4>
                  BRINGING BEAUTY AND
                  GROWTH TO YOUR BUSINESS
                </h4>
                <p>Fostering meaningful connections, Empowering Brands, Expanding Reach: Social Theory offers tailored digital marketing solutions that drive growth, from strategic campaigns to impactful social media management.</p>
              </div>
              <div className={styles.right}>
                <div className="position-relative">
                  <div className={styles.number}>
                    <img src={border} alt="border" />
                    <span>1</span>
                  </div>
                  <div className={styles.texts}>
                    <h6 className={styles.heading1}>Digital Marketing</h6>
                    <p>Transcend into the world of Digital Marketing with us. Enhance your online and physical presence. We offer powerful strategies and campaigns that engage audiences and elevate your brand. Let's journey towards success, unlocking your business's potential for unforgettable industry impact.</p>
                    <ul className={styles.points}>
                      <li>
                        <div>
                          {doubleTick}
                        </div>
                        <span>Social Media Management</span>
                      </li>

                      <li>
                        <div>
                          {doubleTick}
                        </div>
                        <span>Google Ads & Search Engine Optimization</span>
                      </li>

                      <li>
                        <div>
                          {doubleTick}
                        </div>
                        <span>Email Marketing and Customer Engagement</span>
                      </li>
                    </ul>
                  </div>
                  {/* <div ref={bgImgRef} className={styles.bg_img + " " + styles.bg_img1}></div> */}
                </div>
              </div>
            </div>
          </div>
          <div style={{ minHeight: "100%" }} className={styles.panel}>
            <div className={styles.panel2}>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>2</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading2}>Graphic & Logo
                    Designing</h6>
                  <p>Dive into the realm of striking graphic and logo design with us. Our expert team crafts modern, timeless logos, echoing your brand's personality and identity.. By integrating your brand's story with current trends, we create impactful visuals for a lasting impression.</p>
                </div>
              </div>
              <div id="translate3d" className={styles.main + " position-relative"}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>3</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading3}>Content Generation</h6>
                  <p>Every piece of content, from brief captions to comprehensive blog posts, should deliver your message effectively to your audience. Our team of copywriting specialists craft engaging, insightful, and inspiring content, ensuring your brand’s voice resonates loud and clear.</p>
                </div>
                {/* <div className={styles.bg_img + " " + styles.bg_img2}></div> */}
              </div>
            </div>
          </div>
          <div style={{ minHeight: "100%" }} className={styles.panel}>
            <div className={styles.panel3}>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>4</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading4}>Brand Building</h6>
                  <p>Products may be factory-made, but brands are crafted in the mind. Our approach builds brands that resonate with audiences for long-term success. By delivering valuable, meaningful content alongside impactful graphics, we make your brand not just recognized, but remembered. </p>
                  <ul className={styles.points}>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Brand Positioning</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Brand Segmentation and Evaluation</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Brand Visualisation</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Brand Revitalization or Rebranding</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="translate3d" className={styles.main + " position-relative"}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>5</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading5}>UI/UX Designing</h6>
                  <p>Understanding user needs and pain points deeply, we create experiences that address them effectively. Our expert team ensures usability and aesthetic appeal. For any successful website or application, these aspects are crucial. We craft engaging, modern, and visually stunning designs that resonate with users.
                  </p>
                  <ul className={styles.points}>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Audience and Market Research</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Competitive Benchmarking</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Consumer Journey Mapping</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Information Architecture</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>User Empathy Mapping</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Problem Statement</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>User Persona</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Task Flow</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Application Framework Designing</span>
                    </li>
                  </ul>
                </div>
                {/* <div className={styles.bg_img + " " + styles.bg_img3}></div> */}
              </div>
            </div>
          </div>
          <div style={{ minHeight: "100%" }} className={styles.panel}>
            <div className={styles.panel4}>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>6</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading6}>Web development
                    & Mobile Application</h6>
                  <p>Our team develops powerful, responsive websites and mobile apps tailored to your needs. We adeptly navigate challenges to ensure seamless integration. Committed to creating enticing, fast, and appealing solutions, we ensure our work aligns with current trends and exceeds expectations.</p>
                  <ul className={styles.points}>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Full Stack Development</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Ecommerce Platform</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>System Architecture</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Integrations</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="translate3d" className={styles.main + " position-relative"}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>7</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading7}>Market Research</h6>
                  <p>In the vast knowledge landscape lies success's true wealth. We harness this power for deep insight into your target market, competitors, and latest tech and trends. Our goal is to keep you informed, delivering brutally honest yet crucial insights to drive your success.</p>
                  <ul className={styles.points}>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Demographic Research</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Psychographic Research</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Competitive Analysis</span>
                    </li>
                    <li>
                      <div>
                        {doubleTick}
                      </div>
                      <span>Behavioural Analysis</span>
                    </li>
                  </ul>
                </div>
                {/* <div className={styles.bg_img + " " + styles.bg_img4}></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.gobackdesk} onClick={() => navigate(-1)}>
          <div className={styles.texts}>
            <span>Go back</span>
            <div className={styles.arrowback}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.894" height="9.597" viewBox="0 0 18.894 9.597">
                <g transform="translate(-69.053 -1037.201)">
                  <g transform="translate(-150.201 764.698)">
                    <path d="M219.547,277.594h17.6l-4.031,4.011a.29.29,0,0,0,0,.412.3.3,0,0,0,.415,0l4.53-4.508.007-.008a.166.166,0,0,0,.016-.02.1.1,0,0,0,.011-.015l.013-.02a.146.146,0,0,0,.009-.018.091.091,0,0,0,.006-.013l0-.008a.112.112,0,0,0,.007-.019c0-.007,0-.014.006-.022a.194.194,0,0,0,0-.02.091.091,0,0,0,0-.022.119.119,0,0,0,0-.021.13.13,0,0,0,0-.021.093.093,0,0,0,0-.022c0-.007,0-.013,0-.02s0-.014-.006-.022a.18.18,0,0,0-.006-.018.04.04,0,0,1,0-.008.1.1,0,0,0-.007-.014.08.08,0,0,0-.009-.017.2.2,0,0,0-.013-.021.094.094,0,0,0-.011-.015.119.119,0,0,0-.016-.019l-.008-.009-4.53-4.507a.295.295,0,0,0-.415,0,.29.29,0,0,0,0,.411h0l4.031,4.01h-17.6a.291.291,0,1,0,0,.582Z" fill="#333"></path>
                  </g>
                  <path d="M82.916,1046.714a.289.289,0,0,1,0-.411l4.031-4.011h-17.6a.291.291,0,1,1,0-.581h17.6l-4.031-4.01h0a.29.29,0,0,1,0-.411.295.295,0,0,1,.415,0l4.53,4.507.008.009a.089.089,0,0,1,.016.02l.011.014a.1.1,0,0,1,.013.021.061.061,0,0,1,.009.017.049.049,0,0,1,.007.014l0,.008a.107.107,0,0,1,.006.018.162.162,0,0,0,.006.023l0,.02a.094.094,0,0,1,0,.022.091.091,0,0,1,0,.022.08.08,0,0,1,0,.021.1.1,0,0,1,0,.023l0,.019c0,.008,0,.015-.006.022a.163.163,0,0,1-.006.019l0,.007a.046.046,0,0,1-.006.013.113.113,0,0,1-.01.018c0,.007-.008.013-.013.021l-.011.015c0,.006-.011.013-.016.019l-.008.008-4.53,4.508a.3.3,0,0,1-.415,0Z" fill="#a2429e"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.gobackmob} onClick={() => navigate(-1)}>
          <div className={styles.texts}>
            <span>Go back</span>
            <div className={styles.arrowback}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.894" height="9.597" viewBox="0 0 18.894 9.597">
                <g transform="translate(-69.053 -1037.201)">
                  <g transform="translate(-150.201 764.698)">
                    <path d="M219.547,277.594h17.6l-4.031,4.011a.29.29,0,0,0,0,.412.3.3,0,0,0,.415,0l4.53-4.508.007-.008a.166.166,0,0,0,.016-.02.1.1,0,0,0,.011-.015l.013-.02a.146.146,0,0,0,.009-.018.091.091,0,0,0,.006-.013l0-.008a.112.112,0,0,0,.007-.019c0-.007,0-.014.006-.022a.194.194,0,0,0,0-.02.091.091,0,0,0,0-.022.119.119,0,0,0,0-.021.13.13,0,0,0,0-.021.093.093,0,0,0,0-.022c0-.007,0-.013,0-.02s0-.014-.006-.022a.18.18,0,0,0-.006-.018.04.04,0,0,1,0-.008.1.1,0,0,0-.007-.014.08.08,0,0,0-.009-.017.2.2,0,0,0-.013-.021.094.094,0,0,0-.011-.015.119.119,0,0,0-.016-.019l-.008-.009-4.53-4.507a.295.295,0,0,0-.415,0,.29.29,0,0,0,0,.411h0l4.031,4.01h-17.6a.291.291,0,1,0,0,.582Z" fill="#333"></path>
                  </g>
                  <path d="M82.916,1046.714a.289.289,0,0,1,0-.411l4.031-4.011h-17.6a.291.291,0,1,1,0-.581h17.6l-4.031-4.01h0a.29.29,0,0,1,0-.411.295.295,0,0,1,.415,0l4.53,4.507.008.009a.089.089,0,0,1,.016.02l.011.014a.1.1,0,0,1,.013.021.061.061,0,0,1,.009.017.049.049,0,0,1,.007.014l0,.008a.107.107,0,0,1,.006.018.162.162,0,0,0,.006.023l0,.02a.094.094,0,0,1,0,.022.091.091,0,0,1,0,.022.08.08,0,0,1,0,.021.1.1,0,0,1,0,.023l0,.019c0,.008,0,.015-.006.022a.163.163,0,0,1-.006.019l0,.007a.046.046,0,0,1-.006.013.113.113,0,0,1-.01.018c0,.007-.008.013-.013.021l-.011.015c0,.006-.011.013-.016.019l-.008.008-4.53,4.508a.3.3,0,0,1-.415,0Z" fill="#a2429e"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Background>
  )
}

export default Service