import styles from "./Common.module.scss";
import Bounce from 'react-reveal/Bounce'

const Comment = ({ image, name, date, text, isSubComment }) => {
  return (
    <Bounce left>
      <div
        className={`${styles.comment} ${isSubComment && styles.subComment
          } d-flex`}
      >
        <div>
          <img src={image} alt="user-image" />
        </div>
        <div className="d-flex flex-nowrap d-sm-block align-items-center">
          <div
            className={`${styles.comment_detail} d-flex flex-column flex-sm-row flex-nowrap`}
          >
            by {name}&nbsp;<p className="d-none d-sm-block mb-0">/</p>&nbsp;
            <span>{date}</span>
          </div>
          <div className="d-none d-sm-block">
            <p className={styles.comment_txt}>{text}</p>
            <div className={styles.like_reply}>
              <span>Like</span>
              <span>Reply</span>
            </div>
          </div>
        </div>
      </div>

      <div className={`${isSubComment && styles.subComment} d-block d-sm-none`}>
        <p className={styles.comment_txt}>{text}</p>
        <div className={styles.like_reply}>
          <span>Like</span>
          <span>Reply</span>
        </div>
      </div>
    </Bounce>
  );
};

export default Comment;
