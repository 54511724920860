import { useState } from "react";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";
import Button from "../Common/Button";
import styles from "./Project.module.scss";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";
import { Fade } from "react-reveal";

const arrow = "https://mysocialtheory.com/assets/images/arrow.svg";
const gradientArrow = "https://mysocialtheory.com/assets/images/gradient_arrow.svg";


const Project = () => {
  const [activeTab, setActiveTab] = useState(1);

  const renderTab = () => {
    switch (activeTab) {
      case 1:
        return <Tab1 />;
      case 2:
        return <Tab2 />;
      case 3:
        return <Tab3 />;
      case 4:
        return <Tab4 />;
      default:
        return <Tab1 />;
    }
  };

  return (
    <Fade left>
      <section
        key={activeTab}
        className={`${styles.project_main} row mx-0 overflow-x-hidden`}
      >
        <div
          className={`${styles.left_main} col-lg-4 px-0 d-flex flex-column justify-content-between gap-5`}
        >
          <div>
            <h3 className="mb-0 text-center text-lg-start">
              featured <br /> <span>project</span>
            </h3>
            <div className={`${styles.tabs}`}>
              <ul className="ps-0 mb-0 d-flex flex-column">
                <li
                  className={activeTab === 1 && styles.active_tab}
                  onClick={() => setActiveTab(1)}
                >
                  <TypeAnimation
                    sequence={["Basha Foods"]}
                    wrapper="span"
                    speed={0.5}
                  />
                  <motion.span
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    transition={{ duration: 1 }}
                    className={styles.line}
                  ></motion.span>
                  <div>
                    <img
                      src={activeTab === 1 ? gradientArrow : arrow}
                      alt="forward-arrow"
                    />
                  </div>
                </li>
                <li
                  className={activeTab === 2 && styles.active_tab}
                  onClick={() => setActiveTab(2)}
                >
                  <TypeAnimation
                    sequence={["Basha Vapes"]}
                    wrapper="span"
                    speed={0.5}
                  />
                  <motion.span
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    transition={{ duration: 1 }}
                    className={styles.line}
                  ></motion.span>
                  <div>
                    <img
                      src={activeTab === 2 ? gradientArrow : arrow}
                      alt="forward-arrow"
                    />
                  </div>
                </li>
                <li
                  className={activeTab === 3 && styles.active_tab}
                  onClick={() => setActiveTab(3)}
                >
                  <TypeAnimation
                    sequence={["Branding"]}
                    wrapper="span"
                    speed={0.5}
                  />
                  <motion.span
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    transition={{ duration: 1 }}
                    className={styles.line}
                  ></motion.span>
                  <div>
                    <img
                      src={activeTab === 3 ? gradientArrow : arrow}
                      alt="forward-arrow"
                    />
                  </div>
                </li>
                <li
                  className={activeTab === 4 && styles.active_tab}
                  onClick={() => setActiveTab(4)}
                >
                  <TypeAnimation
                    sequence={["Graphic Design"]}
                    wrapper="span"
                    speed={0.5}
                  />
                  <motion.span
                    initial={{ width: 0 }}
                    animate={{ width: "100%" }}
                    transition={{ duration: 1 }}
                    className={styles.line}
                  ></motion.span>
                  <div>
                    <img
                      src={activeTab === 4 ? gradientArrow : arrow}
                      alt="forward-arrow"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <Button text="Visit our all work" link="/portfolio" />
          </div>
        </div>
        <div className="col-lg-8 px-0 text-end">
          {renderTab()}
          <div className="d-block d-lg-none text-center mt-4">
            <Button text="Visit our all work" link="/portfolio" />
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Project;
