import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";
import Background from "../Components/Common/Background";

const Layout = () => {
  const { pathname } = useLocation();
  return (
    <Background backgroundColor="#0d0d0d">
      <Header />
      <Outlet />
      {!(pathname.includes('service') || pathname.includes('what-we-do') || pathname.includes("how-we-do") || pathname.includes("/portfolio")) && <Footer />}
    </Background>
  );
};

export default Layout;