import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import styles from "./Professional.module.scss";
import { useState } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const P1 = "https://mysocialtheory.com/assets/images/team/Kaushil_Main.png";
const P2 = "https://mysocialtheory.com/assets/images/team/Abhijeet_Main.png";
const P3 = "https://mysocialtheory.com/assets/images/team/Dolcie_Main.png";
const gradientArrow = "https://mysocialtheory.com/assets/images/bordered_gradient_arrow.png";

const Professional = () => {
  const [activeSlide, setActiveSlide] = useState(null)

  return (
    <div
      className={`${styles.professionals_main} d-flex flex-column flex-lg-row justify-content-between mx-0 overflow-x-hidden`}
    >
      <Fade left>
        <div

          className={`${styles.left_main} px-0 d-flex flex-column justify-content-center`}
        >
          <h3>
            Meet our team of <br />
            <span>professional</span>
          </h3>
          <div className="d-none d-lg-flex justify-content-center gap-2">
            <div className={`${styles.swiper_btn} swiper_next_btn`}>
              <img src={gradientArrow} alt="next-arrow" />
            </div>
            <div className={`${styles.swiper_btn} swiper_prev_btn`}>
              <img src={gradientArrow} alt="prev-arrow" />
            </div>
          </div>
        </div>
      </Fade>
      <Fade right>
        <div

          className={`${styles.right_main} px-0 overflow-hidden`}
        >
          <Swiper
            breakpoints={{}}
            effect="cards"
            grabCursor={true}
            modules={[EffectCards, Navigation]}
            cardsEffect={{ slideShadows: false }}
            navigation={{
              enabled: true,
              nextEl: ".swiper_next_btn",
              prevEl: ".swiper_prev_btn",
            }}
            onSwiper={swiper => setActiveSlide(swiper.activeIndex)}
            onSlideChange={swiper => setActiveSlide(swiper.activeIndex)}
            className="professionals_slider"
          >
            <SwiperSlide>
              <Link to="/team-details/abhijeet-shah">
                <div className={styles.img_main}>
                  <img className="w-100 h-auto" src={P2} alt="professionals" />
                </div>
                <div className="text-center mt-3">
                  {activeSlide === 0 && <h2>Abhijeet Shah <span>(Founder, CEO)</span></h2>}
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/team-details/kaushil-shah">
                <div className={styles.img_main}>
                  <img className="w-100 h-auto" src={P1} alt="professionals" />
                </div>
                <div className="text-center mt-3">
                  {activeSlide === 1 && <h2>Kaushil Shah <span>(Co-founder, COO)</span></h2>}
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/team-details/jasneet-kaur">
                <div className={styles.img_main}>
                  <img className="w-100 h-auto" src={P3} alt="professionals" />
                </div>
                <div className="text-center mt-3">
                  {activeSlide === 2 && <h2>Jasneet Kaur <span>(Marketing and Design Specialist)</span></h2>}
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>

        </div>
      </Fade>
      <div className="mt-4 d-flex d-lg-none justify-content-center gap-2">
        <div className={`${styles.swiper_btn} swiper_next_btn`}>
          <img src={gradientArrow} alt="next-arrow" />
        </div>
        <div className={`${styles.swiper_btn} swiper_prev_btn`}>
          <img src={gradientArrow} alt="prev-arrow" />
        </div>
      </div>
    </div>
  );
};

export default Professional;
