import Marquee from "react-fast-marquee";
import styles from "./MarqueeSlider.module.scss";
import Zoom from 'react-reveal/Zoom'

const star = "https://mysocialtheory.com/assets/images/star.svg";

const MarqueeSlider = () => {
  return (
    <Zoom>
      <div className={styles.marquee_slider}>
        <div className="d-flex align-items-center">
          <Marquee loop={false}>
            <h2 className={styles.brand_name}>SOCIAL THEORY</h2>
            <img src={star} alt="star-icon" />
            <h2 className={styles.brand_name}>SOCIAL THEORY</h2>
            <img src={star} alt="star-icon" />
            <h2 className={styles.brand_name}>SOCIAL THEORY</h2>
            <img src={star} alt="star-icon" />
          </Marquee>
        </div>
      </div>
    </Zoom>
  );
};

export default MarqueeSlider;
