import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Background from "../Components/Common/Background";
import Fade from 'react-reveal/Fade'

const Kaushil = 'https://mysocialtheory.com/assets/images/team/Kaushil_Main.png'
const KaushilHover = 'https://mysocialtheory.com/assets/images/team/Kaushil_Hover.png'
const Abhijeet = 'https://mysocialtheory.com/assets/images/team/Abhijeet_Main.png'
const AbhijeetHover = 'https://mysocialtheory.com/assets/images/team/Abhijeet_Hover.png'
const Dolcie = 'https://mysocialtheory.com/assets/images/team/Dolcie_Main.png'
const DolcieHover = 'https://mysocialtheory.com/assets/images/team/Dolcie_Hover.png'

const Team = () => {
  const [isImageHover, setIsImageHover] = useState({
    kaushil: false,
    abhijeet: false,
    dolcie: false,
  })
  const navigate = useNavigate();
  const handleteamView = (name) => {
    return navigate(`/team-details/${name}`);
  };

  return (
    <>
      <Background backgroundColor={"#0d0d0d"}>
        <section className="team overflow-x-hidden">
          <div className="container">
            <div className="row g-5 align-items-center justify-content-center flex-wrap">
              <Fade left>
                <div
                  className="team_box"
                  onClick={() => handleteamView("kaushil-shah")}
                  onMouseOver={() => 'ontouchstart' in window ? null : setIsImageHover(prevState => ({ ...prevState, kaushil: !prevState.kaushil }))}
                  onMouseOut={() => 'ontouchstart' in window ? null : setIsImageHover(prevState => ({ ...prevState, kaushil: !prevState.kaushil }))}
                >
                  <div className="col-md-7 col-12">
                    <div className="team_profile">
                      <figure> <img src={isImageHover.kaushil ? KaushilHover : Kaushil} alt="Kaushil" /></figure>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="team_info">
                      <h4>
                        Kaushil Shah
                        <br />
                        (Co-founder, COO)
                      </h4>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div
                  className="team_box flex-row-reverse"
                  onClick={() => handleteamView("abhijeet-shah")}
                  onMouseOver={() => 'ontouchstart' in window ? null : setIsImageHover(prevState => ({ ...prevState, abhijeet: !prevState.abhijeet }))}
                  onMouseOut={() => 'ontouchstart' in window ? null : setIsImageHover(prevState => ({ ...prevState, abhijeet: !prevState.abhijeet }))}
                >
                  <div className="col-md-7 col-12">
                    <div className="team_profile">
                      <figure> <img src={isImageHover.abhijeet ? AbhijeetHover : Abhijeet} alt="Abhijeet" /></figure>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="team_info">
                      <h4>
                        Abhijeet Shah
                        <br />
                        (Founder, CEO)
                      </h4>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade left>
                <div
                  className="team_box"
                  onClick={() => handleteamView("jasneet-kaur")}
                  onMouseOver={() => 'ontouchstart' in window ? null : setIsImageHover(prevState => ({ ...prevState, dolcie: !prevState.dolcie }))}
                  onMouseOut={() => 'ontouchstart' in window ? null : setIsImageHover(prevState => ({ ...prevState, dolcie: !prevState.dolcie }))}
                >
                  <div className="col-md-7 col-12">
                    <div className="team_profile">
                      <figure> <img src={isImageHover.dolcie ? DolcieHover : Dolcie} alt="Dolcie" /></figure>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="team_info">
                      <h4>
                        Jasneet Kaur
                        <br />
                        (Marketing & Design Specialist)
                      </h4>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </section>
      </Background>
    </>
  );
};

export default Team;
