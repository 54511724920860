import { useState } from "react";
import styles from "./Play.module.scss";
import { motion } from "framer-motion";

const Play = ({ constraintsRef, videoRef = null }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  return (
    <motion.div
      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
      dragElastic={0.5}
      className={styles.main}
      whileTap={{ cursor: "grabbing" }}
      drag
      dragConstraints={constraintsRef}
      onClick={() => {
        if (videoRef.current.paused) {
          videoRef.current.play()
          setIsPlaying(true)
        } else {
          videoRef.current.pause()
          setIsPlaying(false)
        }
      }}
    >
      <span>
        {videoRef.current
          ? isPlaying
            ? "PAUSE"
            : "PLAY"
          : "PLAY"

        }</span>
    </motion.div>
  );
};

export default Play;
