import { CHILD, CONTAINER } from "../../Constants/FramerVariant";
import styles from "./Hero.module.scss";
import { motion } from "framer-motion";

const Tab1 = ({ activeTab }) => {
  return (
    <div className={styles.heading}>
      <h1
        key={activeTab}
        className="text-center"
      >
        <span className={styles.blur}>BUILD</span> <span className={styles.blur}>YOUR</span> <span className={styles.blur + " " + styles.gradient}>BRANDS</span><br /><span className={styles.blur}>VOICE!</span>
      </h1>
      <motion.p
        variants={CONTAINER}
        initial="hidden"
        animate="visible"
        className="d-flex flex-wrap justify-content-center gap-1"
      >
        {"Let us guide you in building a compelling brand voice that captivates your audience and fo sters strong connections."
          .split(" ")
          .map((word, index) => (
            <motion.span key={index} variants={CHILD}>
              {word}
            </motion.span>
          ))}
      </motion.p>
    </div>
  );
};

export default Tab1;
