import styles from "../assets/css/service.module.scss";
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade'

const arrow = "https://mysocialtheory.com/assets/images/arrow_up.png";
const client = 'https://mysocialtheory.com/assets/images/client.png'
const concept = 'https://mysocialtheory.com/assets/images/concept.png'
const border = 'https://mysocialtheory.com/assets/images/border.svg'


const PortfolioNew = () => {
    return (
        <>
            <Fade bottom>
                <div className={styles.panel + " " + styles.servicesImg + " overflow-x-hidden"}>
                    <div className={styles.slide_main + " overflow-hidden d-flex"}>
                        <Link to="/client-designs" className={styles.slide_left}>
                            <img src={client} alt="client" />
                        </Link>
                        <Link to="/concept-designs" className={styles.slide_right}>
                            <img src={concept} alt="concept" />
                        </Link>
                    </div>
                </div>
            </Fade>
            <div className={styles.serviceTxt + " services"}>
                <Link to="/client-designs" className="wht_we_do">
                    <div className="number">
                        <img src={border} alt="border" />
                        <span>01</span>
                    </div>
                    <div className="text">
                        <div className="d-flex gap-2 align-items-start mb-2">
                            <h1 className="mb-0">CLIENT</h1>
                            <div style={{ width: "100%", maxWidth: "40px" }}>
                                <img className="w-100 h-auto" src={arrow} alt="link-arrow" />
                            </div>
                        </div>
                        <div>
                            <span></span>
                            <span>DESIGNS</span>
                            <span></span>
                        </div>
                        <p>Immerse your business in a symphony of success with our unmatched expertise. We blend the strategic marketing and beautiful designs along with data driven insights that come together in a perfect harmony that captivates the target audience. Experience a simple yet elegant way of transformational growth and designs to take your business towards remarkable achievements.</p>
                    </div>
                </Link>
                <Link to="/concept-designs" className="how_we_do">
                    <div className="number">
                        <img src={border} alt="border" />
                        <span>02</span>
                    </div>
                    <div className="text">
                        <div className="d-flex align-items-start mb-2">
                            <h1 className="mb-0">CONCEPT</h1>
                            <div style={{ width: "100%", maxWidth: "40px" }}>
                                <img className="w-100 h-auto" src={arrow} alt="link-arrow" />
                            </div>
                        </div>
                        <div>
                            <span></span>
                            <span>DESIGNS</span>
                            <span></span>
                        </div>
                        <p>We make sure what we do and how we do follows a way of analysing everything properly that gives the right results. We are transparent and objective driven. Our idea is to place our clients with a team that suits them the best and exceeds all expectations.</p>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default PortfolioNew