import { useRef } from 'react'
import styles from '../../assets/css/blogDetails.module.scss'
import Play from '../../Components/PlayButton/Play'

const img1 = 'https://mysocialtheory.com/assets/images/blog/blog1-1.jpg'
const img2 = 'https://mysocialtheory.com/assets/images/blog/blog1-2.png'
const img3 = 'https://mysocialtheory.com/assets/images/blog/blog1-3.png'
const img4 = 'https://mysocialtheory.com/assets/images/blog/blog1-4.png'
const quote = 'https://mysocialtheory.com/assets/images/quote.svg'
const shoeVideo = 'https://mysocialtheory.com/assets/videos/blog1.mp4'


const Blog1 = () => {
    const constraintsRef = useRef(null);
    const videoRef = useRef(null)

    return (
        <div className={styles.main + " px-2 px-md-4"}>
            <h3 className={styles.heading}>Accelerating UX Maturity With A Breakthrough Project</h3>
            <div className={styles.contents}>
                <i>We think we know everything about Marketing. But do we really?</i>
                <p>The internet is flooded with extensive amounts of information. With my knowledge and learnings, I am here to help you understand better about where to begin with marketing through this article.</p>
                <i>Let’s Begin…</i>
                <p>What’s the first thought that comes to our mind when we see a mouse shaped figure or a cup or a waffle? Any Guesses?? <i>Mickey Mouse</i></p>
                <p>What’s the first thought that comes to our mind when we see a sneaker with a swoosh sign or the logo “Just Do It” <i>Nike</i></p>
                <p>Well, What is the magical world of Disney if not a brand with remarkable marketing strategies, with Mickey mouse as it’s mascot. And what is Nike if not the biggest marketer in the sports world.</p>
            </div>
            <div className={`${styles.assetsMain} d-flex gap-4 flex-column flex-md-row`}>
                <div className='w-100'>
                    <img className={styles.blogAssets} src={img1} alt="disney-land" />
                </div>
                <div className='w-100 position-relative' ref={constraintsRef}>
                    <video ref={videoRef} loop mute className={styles.blogAssets} src={shoeVideo}></video>
                    <Play constraintsRef={constraintsRef} videoRef={videoRef} />
                </div>
            </div>
            <div className={styles.contents}>
                <p>Ever since I was a kid, I was attracted to TV commercials, with an endless desire to attain all the fancy products shown. I guess who isn’t? That’s what the purpose of marketing is. Right? Attracting people!! From little kids to adults.</p>
                <p>Digital marketing on the other hand is trending in today’s fast pacing world where everything is just a click/touch away. It hosts new and advanced marketing platforms like Instagram, Facebook, Youtube, Snapchat, etc. which has never been more significant.</p>
                <p>Definition of Marketing according to AMA,
                    <br />
                    Marketing is the activity, set of institutions, and processes for creating, communicating, delivering, and exchanging offerings that have value for customers, clients, partners, and society at large.- AMA
                </p>
            </div>
            <div className={styles.subHead}>
                <h5>The Fundamentals of Marketing</h5>
                <div className={styles.contents}>
                    <p>When we see creative marketing campaigns, we think it’s all about creativity but it is mostly based on science. About applying time and tested principles/laws which provide measurable results. It’s about providing the right message/ memo to the right individuals at the right time. Marketing helps the brands to create a strong position in the customer’s minds.</p>
                    <p>Marketing precedes product: The process of marketing starts even before the manufacturing of a product by understanding and studying customer’s needs and buying behaviours. When you value customers and satisfy their needs, the product sells itself.</p>
                    <p>But marketing doesn’t end there, it’s an ongoing process which continues even after the sales, for keeping the existing customers satisfied and happy, to gain their trust and loyalty in the long run. The best marketing is through word of mouth. People trust and believe other people’s reviews and suggestions. So a happy customer will always bring in 10 more clients.</p>
                    <p>Marketing is not only about the products but about perceptions, about how consumers perceive a product. It’s not based on reality but illusions. Perception formed by the brand becomes reality. For example: The perception of owning an Apple product is a status symbol. It makes people feel special.</p>
                    <p>Product > Marketing: Sometimes, marketing is used in unethical ways to sell and promote bad/low quality products. Lower prices may be attractive in the short run but higher quality is always preferred and remembered for a long time. Such products will sell themselves with minimal marketing. Eg. BMW.</p>
                    <p>Good Communication is the key to good marketing.</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>Digital Marketing V/S Traditional Marketing</h5>
                <div className={styles.contents}>
                    <p>Traditional The word itself means long-established. It will never go out of style. TV’s, newspapers, radios, hoardings, etc. are marketing mediums used widely even in the current digital era.</p>
                    <p>Digital describes electronic tech, done online through digital devices on platforms like social media, emails, SEO’s, etc.</p>
                    <p>Both the mediums are widely used depending upon the type of product/service, the audience it wants to reach, the message it wants to convey. The mediums could change, but not the basics of marketing. Let’s take a look:</p>
                    <p>A generic product with a wide range of target audience is easy to be marketed on TV ads, radios as it has a reach of over millions of households. Especially in a country like India where, digital area is still developing and millions still don't have access to the internet. But this same medium won’t work for personalised communications, deep marketing and measuring results. No direct connection with the desired customers.</p>
                    <p>Whereas, digital marketing is great for reaching affluent English speakers not only nationally but globally. It involves higher interaction through direct/ immediate responses and target desired audience. It gives analysis about the working of the strategies/ campaigns, at lower costs. The results can be measured and effective decisions made along with natural sales.</p>
                    <p>With the evolving world and technology, sure DM is a viable and better option. It will cover most of the marketing under its wing but will not entirely replace the traditional form.</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>CATT Marketing Funnel- How to make Wealth?</h5>
                <div className={styles.contents}>
                    <p>The word funnel works exactly as it’s shaped here. The next and important step is understanding and following the CATT framework for a successful marketing campaign to generate wealth.</p>
                    <div className={styles.imgContainer}>
                        <img src={img2} alt="lifecycle" />
                    </div>
                    <i>The formula for Wealth = n^CATT</i>
                    <p>Where, (n)- Niche, (C)- Content, (A)-Attention, (T)-Trust, (T)-Transaction</p>
                    <p>Understanding and choosing your Niche is really important. As that’s the starting point of your success. You cannot be everything to everyone.</p>
                    <ol className={styles.points}>
                        <li>
                            <p><span>Content : </span> Having useful, timeless content within your niche is like laying the foundation for your house. It is the heart and soul. This is how you will attract target customers. Content can be blogs, YouTube videos, etc.</p>
                        </li>
                        <li>
                            <p><span>Attention : </span>Once content is created, generating traffic through social media, ads, SEO’s, etc. is an important factor. There is no point in a published post if there is nobody to look, read or watch it.</p>
                        </li>
                        <li>
                            <p><span>Trust : </span>Without trust, everything falls apart. Only a trustworthy brand converts strangers into loyal customers. It is done by deep marketing, personalized communication, re-targeting, etc.</p>
                        </li>
                        <li>
                            <p><span>Transaction : </span>This is where sales happen, money comes in. It is where you convert your leads into customers/buyers. When you have targeted the right audience, they are interested in buying the product, trust is built, the sales will happen naturally. If not naturally, webinars, sales pages, sales calls, etc. will help create sales.</p>
                        </li>
                    </ol>
                    <p>This methodological framework will help in creating wealth.</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>Integrated Digital Marketing (IDM)</h5>
                <div className={styles.contents}>
                    <p>The CATT funnel was a framework showing how an individual goes through different stages, but we learn how to execute it through IDM.</p>
                    <i>Taking example from my recent favorite movie, Avengers End Game, we learnt that each infinity stone, even being powerful on its own, wouldn’t serve the purpose of the villain without combining them all together, to bring an impact towards the human race.</i>
                    <p>Similarly, coordinating all the digital marketing tools like email marketing, SEO, social media, content writing, etc. gives optimal results and benefits. That is Integrated Digital Marketing. All the digital tools work hand in hand, complementing each other.</p>
                    <div className={styles.imgContainer}>
                        <img src={img3} alt="marketing-stret." />
                    </div>
                    <p>From the above figure we understand, Content drives more attention through Paid Advertising, giving a boost and SEO’s on social media platforms and building trust with customers through email marketing further making sales. It’s all inter-related.</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>Personal Branding</h5>
                <div className={styles.contents}>
                    <i>Oprah! Elon Musk! Selena Gomez! Ratan Tata! Who are these people? When we hear of them, what do we think of? - <span >A brand</span>. They are well established brand names.</i>
                    <p>While Apple, McD, etc. are brands recognized by people for their product and services. Personal brands are associated with people, by their personal stories which are remembered for a lifetime. It immediately tells us their area of expertise. Human beings are social creatures who automatically tend to connect/resonate with other people’s lives. Thus, generates further loyalty towards the brands. A familiar face helps to build trust and make transactions better.</p>
                    <p>This makes us realize How important is Personal Branding. A best known brand will always beat the best in the industry. It’s important to be known to the world for your work rather than being best and hidden. Even though these brands cannot be sold/ invested upon. Their influence leads to the rise of many brands further. They become influencers and brand ambassadors for their companies.</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>Evolution of Personal Branding : Mass Trust Blueprint</h5>
                <div className={styles.contents}>
                    <p>Now the question comes how to evolve a personal brand?</p>
                    <p>It’s about building trust among mass amounts of people through your area of expertise. Here's a time tested blueprint of a constant cycle with different stages to achieve those results.</p>
                    <div className={styles.imgContainer}>
                        <img src={img4} alt="marketing-stret." />
                    </div>
                    <ol className={styles.points}>
                        <li>
                            <p><span>Learn : </span>Humans are always evolving so it’s important to keep learning new skills, concepts and understanding them. Remembering and practising the facts and procedures. Eg. Singers are always learning new rhythms and melodies and practising constantly.</p>
                        </li>
                        <li>
                            <p><span>Work : </span>All knowledge becomes obsolete when not applied. It’s important to get ideas for implementation, skills for work. This is what helps you gain experience. Eg. I watched a tutorial on how to make Pies but didn’t cook/bake, so will I remember what I learnt? Absolutely not.</p>
                        </li>
                        <li>
                            <p><span>Blog : </span>You are able to create unique content only when you combine your learnings with your personal experience. Writing helps you understand the concepts even better.</p>
                        </li>
                        <li>
                            <p><span>Consult : </span>Once you have gained the knowledge from all the steps above, you can advise/help people for their businesses.</p>
                        </li>
                        <li>
                            <p><span>Mentor : </span>Teaching what you learned to numerous other people, enhancing your skills even more to a next level.</p>
                        </li>
                        <li>
                            <p><span>Startup : </span>With all the understanding developed about your skills and the problem areas you are ready to start your own brand/company.</p>
                        </li>
                    </ol>
                    <p>With each step/stage, you reach a new and higher level of clarity and understanding. Once the cycle completes and you are successful. You can invest further in new courses, skill sets and carry on further renewing the cycle.</p>
                    <p>I would like to Conclude with the following quote,</p>
                </div>
            </div>
            <div className={styles.quote}>
                <div className={styles.quoteLogo}>
                    <img className='w-100 h-auto' src={quote} alt="quote" />
                </div>
                <div className={styles.text}>
                    <i>Content builds relationships. Relationships are built on trust. Trust drives revenue.</i>
                    <p>- Andrew Davis</p>
                </div>
            </div>
            <div className={styles.contents}>
                <p>I hope you are leaving here with a feeling of satisfaction and curiosity to learn more. Thankyou for investing your time.
                    <br />
                    If you liked this article or it helped you understand the topic a little better, please leave a comment. :)
                </p>
            </div>
        </div>
    )
}

export default Blog1