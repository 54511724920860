import Bounce from 'react-reveal/Bounce';
import Marquee from "react-fast-marquee";
import { Link } from 'react-router-dom';

const t1 = 'https://mysocialtheory.com/assets/images/trustedby/1.png'
const t2 = 'https://mysocialtheory.com/assets/images/trustedby/2.png'
const t3 = 'https://mysocialtheory.com/assets/images/trustedby/3.png'
const t4 = 'https://mysocialtheory.com/assets/images/trustedby/4.png'
const t5 = 'https://mysocialtheory.com/assets/images/trustedby/5.png'
const t6 = 'https://mysocialtheory.com/assets/images/trustedby/6.png'
const t7 = 'https://mysocialtheory.com/assets/images/trustedby/7.png'
const t8 = 'https://mysocialtheory.com/assets/images/trustedby/8.png'
const t9 = 'https://mysocialtheory.com/assets/images/trustedby/9.png'

const CompanySlider = () => {
  const trustedCompany = [
    {
      img: t1,
      link: "/client-designs/basha-vapes"
    },
    {
      img: t2,
      link: null
    },
    {
      img: t3,
      link: "/client-designs/basha-food"
    },
    {
      img: t4,
      link: "/client-designs/wsj"
    },
    {
      img: t5,
      link: "/client-designs/fresh-slice-kamloops"
    },
    {
      img: t6,
      link: "/client-designs/city-slice-pizzeria"
    },
    {
      img: t7,
      link: "/client-designs/k-enterprises"
    },
    {
      img: t8,
      link: null
    },
    {
      img: t9,
      link: null
    }
  ];
  return (
    <>
      <section className="company_list">
        <div className="container">
          <div className="heading">
            <h2>TRUSTED BY</h2>
          </div>
          <Bounce left cascade>
            <Marquee>
              {trustedCompany.map((items, index) => {
                return (
                  <Link to={items.link ?? ""} key={index} className="company_brands me-3 me-md-5">
                    <img src={items.img} alt="company-images" />
                  </Link>
                );
              })}
            </Marquee>
          </Bounce>
        </div>
      </section>
    </>
  );
};

export default CompanySlider;
