import { Bounce } from "react-reveal"
import { useState } from "react"
import data from '../Constants/ConceptDesign'
import styles from '../assets/css/conceptDesign.module.scss'
import { Link } from "react-router-dom"

const ConceptDesigns = () => {
  const [activeTab, setActiveTab] = useState('GraphicDesign')

  const handleTabClick = ({ currentTarget }) => {
    setActiveTab(currentTarget.getAttribute('data-tab-active'))
  }

  return (
    <div className={`${styles.main} px-4`}>
      <div className={styles.tabMain}>
        <Bounce left cascade>
          <div data-tab-active="GraphicDesign" className={`${styles.tabBtn} ${activeTab === "GraphicDesign" ? styles.tabBtnActive : null}`} onClick={handleTabClick}>
            <button>Graphic Design</button>
          </div>
          <div data-tab-active="LogoDesign" className={`${styles.tabBtn} ${activeTab === "LogoDesign" ? styles.tabBtnActive : null}`} onClick={handleTabClick}>
            <button>Logo Design</button>
          </div>
          <div data-tab-active="WebDesign" className={`${styles.tabBtn} ${activeTab === "WebDesign" ? styles.tabBtnActive : null}`} onClick={handleTabClick}>
            <button>Web Design</button>
          </div>
          <div data-tab-active="Branding/Packging" className={`${styles.tabBtn} ${activeTab === "Branding/Packging" ? styles.tabBtnActive : null}`} onClick={handleTabClick}>
            <button>Branding/Packging</button>
          </div>
          <div data-tab-active="UI/UX" className={`${styles.tabBtn} ${activeTab === "UI/UX" ? styles.tabBtnActive : null}`} onClick={handleTabClick}>
            <button>UI/UX</button>
          </div>
        </Bounce>
      </div>

      <div className={`${styles.rowMain} row mx-0 gap-4 gap-md-0`}>
        <Link to={`/concept-designs/${data[activeTab].images[0].link}`} className='col-12 col-md-7 p-0 d-flex position-relative'>
          <img className='w-100 h-auto pe-2 pe-lg-3 rounded-5' src={data[activeTab].images[0].img} alt={activeTab} />
          <div className={styles.overlay}></div>
        </Link>
        <Link to={`/concept-designs/${data[activeTab].images[1].link}`} className='col-md-5 p-0 d-flex position-relative'>
          <img className='w-100 h-auto ps-2 ps-lg-3 rounded-5' src={data[activeTab].images[1].img} alt={activeTab} />
          <div className={styles.overlay}></div>
        </Link>
      </div>

      {(data[activeTab].images[2] || data[activeTab].images[3])
        && <div className={`${styles.rowMain} row mx-0 gap-4 gap-md-0`}>
          {data[activeTab].images[2] && <Link to={`/concept-designs/${data[activeTab].images[2].link}`} className='col-md-5 p-0 d-flex position-relative'>
            <img className='w-100 h-auto pe-2 pe-lg-3 rounded-5' src={data[activeTab].images[2].img} alt={activeTab} />
            <div className={styles.overlay}></div>
          </Link>}
          {data[activeTab].images[3] && <Link to={`/concept-designs/${data[activeTab].images[3].link}`} className='col-12 col-md-7 p-0 d-flex position-relative'>
            <img className='w-100 h-auto ps-2 ps-lg-3 rounded-5' src={data[activeTab].images[3].img} alt={activeTab} />
            <div className={styles.overlay}></div>
          </Link>}
        </div>}

      {(data[activeTab].images[4] || data[activeTab].images[5])
        && <div className={`${styles.rowMain} row mx-0 gap-4 gap-md-0`}>
          {data[activeTab].images[4] && <Link to={`/concept-designs/${data[activeTab].images[4].link}`} className='col-12 col-md-7 p-0 d-flex position-relative'>
            <img className='w-100 h-auto pe-2 pe-lg-3 rounded-5' src={data[activeTab].images[4].img} alt={activeTab} />
            <div className={styles.overlay}></div>
          </Link>}
          {data[activeTab].images[5] && <Link to={`/concept-designs/${data[activeTab].images[5].link}`} className='col-md-5 p-0 d-flex position-relative'>
            <img className='w-100 h-auto ps-2 ps-lg-3 rounded-5' src={data[activeTab].images[5].img} alt={activeTab} />
            <div className={styles.overlay}></div>
          </Link>}
        </div>}

      {data[activeTab].images[6]
        && <div className={`${styles.rowMain} row mx-0 gap-4 gap-md-0`}>
          {data[activeTab].images[6] && <Link to={`/concept-designs/${data[activeTab].images[6].link}`} className='col-md-5 p-0 d-flex position-relative'>
            <img className='w-100 h-auto pe-2 pe-lg-3 rounded-5' src={data[activeTab].images[6].img} alt={activeTab} />
            <div className={styles.overlay}></div>
          </Link>}
        </div>}

    </div>
  )
}

export default ConceptDesigns