import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade'
import styles from "../assets/css/service.module.scss";

const arrow = "https://mysocialtheory.com/assets/images/arrow_up.png";
const whtWeDo = 'https://mysocialtheory.com/assets/images/slide2_left.png'
const howWeDo = 'https://mysocialtheory.com/assets/images/slide2_right.png'
const border = 'https://mysocialtheory.com/assets/images/border.svg'

const ServiceNew = () => {
    return (
        <>
            <Fade bottom>
                <div className={styles.panel + " " + styles.servicesImg + " overflow-x-hidden"}>
                    <div className={styles.slide_main + " overflow-hidden"}>
                        <Link to="/what-we-do" className={styles.slide_left}>
                            <img src={whtWeDo} alt="what-we-do" />
                        </Link>
                        <Link to="/how-we-do" className={styles.slide_right}>
                            <img src={howWeDo} alt="how-we-do" />
                        </Link>
                    </div>
                </div>
            </Fade>
            <div className={styles.serviceTxt + " services"}>
                <Link to="/what-we-do" className="wht_we_do">
                    <div className="number">
                        <img src={border} alt="border" />
                        <span>01</span>
                    </div>
                    <div className="text">
                        <div className="d-flex gap-2 align-items-start mb-2">
                            <h1 className="mb-0">WHAT</h1>
                            <div style={{ width: "100%", maxWidth: "40px" }}>
                                <img className="w-100 h-auto" src={arrow} alt="link-arrow" />
                            </div>
                        </div>
                        <div>
                            <span className="red"></span>
                            <span>WE DO</span>
                            <span className="red"></span>
                        </div>
                        <p>We transcend conventional bounds by crafting unique digital experiences, curating thumb stopping narratives and cutting-edge strategies that sculpt an echo chamber for your brand’s voice. </p>
                    </div>
                </Link>
                <Link to="/how-we-do" className="how_we_do">
                    <div className="number">
                        <img src={border} alt="border" />
                        <span>02</span>
                    </div>
                    <div className="text">
                        <div className="d-flex gap-2 align-items-start mb-2">
                            <h1 className="mb-0">HOW</h1>
                            <div style={{ width: "100%", maxWidth: "40px" }}>
                                <img className="w-100 h-auto" src={arrow} alt="link-arrow" />
                            </div>
                        </div>
                        <div>
                            <span></span>
                            <span>WE DO</span>
                            <span></span>
                        </div>
                        <p>As dreamers united, we infuse every strategy with team spirit, intertwining innovation and insight. Together, we spin visions into reality, crafting digital triumphs that define the essence of Social Theory.</p>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default ServiceNew   