import styles from "./Project.module.scss";
import Fade from 'react-reveal'
import { Link } from "react-router-dom";

const bashaFoods = 'https://mysocialtheory.com/assets/videos/Basha_Foods.mp4'

function Tab1() {
  return (
    <div className={`${styles.tab1_main} d-flex flex-column gap-4`}>
      <Fade right>
        <Link to="/client-designs/basha-food" className="d-flex">
          <video autoPlay muted loop className="w-100 h-auto" src={bashaFoods} />
        </Link>
      </Fade>
    </div>
  );
}

export default Tab1;
