import { useRef, useState } from "react";
import styles from "./Hero.module.scss";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { Fade } from "react-reveal";
// import Play from "../PlayButton/Play";

// const hero = "https://mysocialtheory.com/assets/images/hero.png";
const hero = "https://mysocialtheory.com/assets/images/logo-hero.png";


const Hero = () => {
  const [activeTab, setActiveTab] = useState(1);
  const constraintsRef = useRef(null);

  const renderTab = () => {
    if (activeTab === 1) {
      return <Tab1 activeTab={activeTab} />;
    }
    if (activeTab === 2) {
      return <Tab2 activeTab={activeTab} />;
    }
    if (activeTab === 3) {
      return <Tab3 activeTab={activeTab} />;
    }
  };

  return (
    <div className={`${styles.hero_main} row mx-0 overflow-hidden`}>
      <Fade left>
        <div
          className={`${styles.social_links} d-none col-sm-1 px-0 d-sm-flex align-items-center justify-content-center`}
        >
          <a href="https://www.facebook.com/mysocialtheory" target="_new">
            <FaFacebookF />
            <TypeAnimation sequence={["Facebook"]} wrapper="span" speed={0.5} />
          </a>
          <a href="https://www.instagram.com/socialtheories/" target="_new">
            <FaInstagram />
            <TypeAnimation sequence={["Instagram"]} wrapper="span" speed={0.5} />
          </a>
          <a href="https://www.linkedin.com/company/mysocialtheory" target="_new">
            <FaLinkedinIn />
            <TypeAnimation sequence={["LinkedIn"]} wrapper="span" speed={0.5} />
          </a>
        </div>
      </Fade>
      <div className="col-sm-11 px-0">
        <div className="h-100 row mx-0">
          <div className="col-md-11 px-0 px-sm-3 d-flex flex-column justify-content-between align-items-center">
            {renderTab()}
            <Fade bottom key={activeTab}>
              <div
                key={activeTab}
                className={styles.hero_img}
                ref={constraintsRef}
              >
                <img className="w-100 h-auto" src={hero} alt="hero-image" />
                {/* <Play constraintsRef={constraintsRef} /> */}
              </div>

            </Fade>
          </div>
          <Fade right>
            <div
              className={`${styles.tab_button_main} col-md-1 my-4 mt-md-0 d-flex flex-md-column align-items-center justify-content-center`}
            >
              <div
                className={`${styles.tab_button} ${activeTab === 1 && styles.active_tab_btn
                  }`}
                onClick={() => setActiveTab(1)}
              >
                <button>01</button>
              </div>
              <div
                className={`${styles.tab_button} ${activeTab === 2 && styles.active_tab_btn
                  }`}
                onClick={() => setActiveTab(2)}
              >
                <button>02</button>
              </div>
              <div
                className={`${styles.tab_button} ${activeTab === 3 && styles.active_tab_btn
                  }`}
                onClick={() => setActiveTab(3)}
              >
                <button>03</button>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Hero;
