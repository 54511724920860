// basha-food
const bashaFoodBanner = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFoodBanner.png'
const bashafoodProb1 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashafoodProb1.png'
const bashafoodProb2 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashafoodProb2.png'
const bashafood1 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood1.png'
const bashafood2 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood2.png'
const bashafood3 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood3.png'
const bashafood4 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood4.png'
const bashafood5 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood5.png'
const bashafood6 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood6.png'
const bashafood7 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood7.png'
const bashafood8 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood8.png'
const bashafood9 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood9.png'
const bashafood10 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood10.png'
const bashafood11 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood11.png'
const bashafood12 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood12.png'
const bashafood13 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFood13.png'
const card1 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFoodCard1.png'
const card2 = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFoodCard2.png'

// wsj
const wsjBanner = 'https://mysocialtheory.com/assets/images/client/wsj/wsjBanner.png'
const wsjProb1 = 'https://mysocialtheory.com/assets/images/client/wsj/wsjProb1.png'
const wsjProb2 = 'https://mysocialtheory.com/assets/images/client/wsj/wsjProb2.jpeg'
const wsj1 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj1.png'
const wsj2 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj2.png'
const wsj3 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj3.png'
const wsj4 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj4.png'
const wsj5 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj5.png'
const wsj6 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj6.png'
const wsj7 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj7.png'
const wsj8 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj8.png'
const wsj9 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj9.png'
const wsj10 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj10.png'
const wsj11 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj11.png'
const wsj12 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj12.png'
const wsj13 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj13.png'
const wsj14 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj14.png'
const wsj15 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj15.png'
const wsj16 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj16.png'
const wsj17 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj17.png'
const wsj18 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj18.png'
const wsj19 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj19.png'
const wsj20 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj20.png'
const wsj21 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj21.png'
const wsj22 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj22.png'
const wsj23 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj23.png'
const wsj24 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj24.png'
const wsj25 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj25.png'
const wsj26 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj26.png'
const wsj27 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj27.png'
const wsj28 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj28.png'
const wsj29 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj29.png'
const wsj30 = 'https://mysocialtheory.com/assets/images/client/wsj/wsj30.png'

// city-slice-pizzeria
const citySliceBanner = 'https://mysocialtheory.com/assets/images/client/citySlice/citySliceBanner.png'
const citySlice1 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice1.png'
const citySlice2 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice2.png'
const citySlice3 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice3.png'
const citySlice4 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice4.png'
const citySlice5 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice5.png'
const citySlice6 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice6.png'
const citySlice7 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice7.png'
const citySlice8 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice8.png'
const citySlice9 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice9.png'
const citySlice10 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice10.png'
const citySlice11 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice11.png'
const citySlice12 = 'https://mysocialtheory.com/assets/images/client/citySlice/citySlice12.png'

// k-enterprises
const kEnterprisesBanner = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kEnterprisesBanner.png'
const kEnterprisesLogo1 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kEnterprisesLogo1.png'
const kenterprises1 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises1.png'
const kenterprises2 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises2.png'
const kenterprises3 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises3.png'
const kenterprises4 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises4.png'
const kenterprises5 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises5.png'
const kenterprises6 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises6.png'
const kenterprises7 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises7.png'
const kenterprises8 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises8.png'
const kenterprises9 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises9.png'
const kenterprises10 = 'https://mysocialtheory.com/assets/images/client/kEnterprises/kenterprises10.png'

// fresh-slice-kamloops
const freshsliceBanner = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshSllicebanner.png'
const freshslice1 = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshslice1.jpg'
const freshslice2 = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshslice2.jpg'
const freshslice3 = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshslice3.jpg'
const freshslice4 = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshslice4.jpg'
const freshslice5 = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshslice5.jpg'
const freshslice6 = 'https://mysocialtheory.com/assets/images/client/freshSlice/freshslice6.jpg'

// basha-vapes
const bashavapesBanner = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapesBanner.png'
const bashavapes1 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes1.png'
const bashavapes2 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes2.png'
const bashavapes3 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes3.png'
const bashavapes4 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes4.png'
const bashavapes5 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes5.png'
const bashavapes6 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes6.png'
const bashavapes7 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes7.png'
const bashavapes8 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes8.png'
const bashavapes9 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes9.png'
const bashavapes10 = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapes10.png'

// bedi-finance
const bedifinanceBanner = 'https://mysocialtheory.com/assets/images/client/bediFinance/bediBanner.png'
const bedifinance1 = 'https://mysocialtheory.com/assets/images/client/bediFinance/bedi1.png'
const bedifinance2 = 'https://mysocialtheory.com/assets/images/client/bediFinance/bedi2.png'
const bedifinance3 = 'https://mysocialtheory.com/assets/images/client/bediFinance/bedi3.png'
const bedifinance4 = 'https://mysocialtheory.com/assets/images/client/bediFinance/bedi4.png'
const bedifinance5 = 'https://mysocialtheory.com/assets/images/client/bediFinance/bedi5.png'
const bedifinance6 = 'https://mysocialtheory.com/assets/images/client/bediFinance/bedi6.png'


//burger
const burgerBanner = "https://mysocialtheory.com/assets/images/concept/graphic/burger/Burger Graphic.png"
const burger1 = 'https://mysocialtheory.com/assets/images/concept/graphic/burger/Graphic Design - Burger 1.jpg'
const burger2 = 'https://mysocialtheory.com/assets/images/concept/graphic/burger/Graphic Design - Burger 2.jpg'
const burger3 = 'https://mysocialtheory.com/assets/images/concept/graphic/burger/Graphic Design - Burger 3.jpg'
const burger4 = 'https://mysocialtheory.com/assets/images/concept/graphic/burger/Graphic Design - Burger 4.jpg'
const burger5 = 'https://mysocialtheory.com/assets/images/concept/graphic/burger/Graphic Design - Burger 5.jpg'

// bread
const breadBanner = "https://mysocialtheory.com/assets/images/concept/graphic/bread/Bread Graphic.png"
const bread1 = 'https://mysocialtheory.com/assets/images/concept/graphic/bread/Graphic Design - Bread 1.jpg'
const bread2 = 'https://mysocialtheory.com/assets/images/concept/graphic/bread/Graphic Design - Bread 2.jpg'
const bread3 = 'https://mysocialtheory.com/assets/images/concept/graphic/bread/Graphic Design - Bread 3.jpg'
const bread4 = 'https://mysocialtheory.com/assets/images/concept/graphic/bread/Graphic Design - Bread 4.jpg'
const bread5 = 'https://mysocialtheory.com/assets/images/concept/graphic/bread/Graphic Design - Bread 5.jpg'

// electronics
const electronicsBanner = "https://mysocialtheory.com/assets/images/concept/graphic/electronics/Electronics Graphic.png"
const electronics1 = 'https://mysocialtheory.com/assets/images/concept/graphic/electronics/Graphic Design - Electronics 1.jpg'
const electronics2 = 'https://mysocialtheory.com/assets/images/concept/graphic/electronics/Graphic Design - Electronics 2.jpg'
const electronics3 = 'https://mysocialtheory.com/assets/images/concept/graphic/electronics/Graphic Design - Electronics 3.jpg'
const electronics4 = 'https://mysocialtheory.com/assets/images/concept/graphic/electronics/Graphic Design - Electronics 4.jpg'
const electronics5 = 'https://mysocialtheory.com/assets/images/concept/graphic/electronics/Graphic Design - Electronics 5.jpg'

// fashion
const fashionBanner = "https://mysocialtheory.com/assets/images/concept/graphic/fashion/Fashion Graphic.png"
const fashion1 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 1.jpg'
const fashion2 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 2.jpg'
const fashion3 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 3.jpg'
const fashion4 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 4.jpg'
const fashion5 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 5.jpg'
const fashion6 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 6.jpg'
const fashion7 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 7.jpg'
const fashion8 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 8.jpg'
const fashion9 = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion/Graphic Design - Fashion 9.jpg'

// indianResto
const indianRestoBanner = "https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Indian Restaurant Graphic.png"
const indianResto1 = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Graphic Design - Indian restaurant 1.jpg'
const indianResto2 = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Graphic Design - Indian restaurant 2.jpg'
const indianResto3 = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Graphic Design - Indian restaurant 3.jpg'
const indianResto4 = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Graphic Design - Indian restaurant 4.jpg'
const indianResto5 = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Graphic Design - Indian restaurant 5.jpg'
const indianResto6 = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto/Graphic Design - Indian restaurant 6.jpg'

// housing
const housingBanner = "https://mysocialtheory.com/assets/images/concept/graphic/housing/Housing Graphic.png"
const housing1 = 'https://mysocialtheory.com/assets/images/concept/graphic/housing/Graphic Design - Housing 1.jpg'
const housing2 = 'https://mysocialtheory.com/assets/images/concept/graphic/housing/Graphic Design - Housing 2.jpg'
const housing3 = 'https://mysocialtheory.com/assets/images/concept/graphic/housing/Graphic Design - Housing 3.jpg'
const housing4 = 'https://mysocialtheory.com/assets/images/concept/graphic/housing/Graphic Design - Housing 4.jpg'
const housing5 = 'https://mysocialtheory.com/assets/images/concept/graphic/housing/Graphic Design - Housing 5.jpg'

// sports
const sportsBanner = "https://mysocialtheory.com/assets/images/concept/graphic/sports/Burger Graphic.png"
const sports1 = 'https://mysocialtheory.com/assets/images/concept/graphic/sports/Graphic Design - Sports 1.jpg'
const sports2 = 'https://mysocialtheory.com/assets/images/concept/graphic/sports/Graphic Design - Sports 2.jpg'
const sports3 = 'https://mysocialtheory.com/assets/images/concept/graphic/sports/Graphic Design - Sports 3.jpg'
const sports4 = 'https://mysocialtheory.com/assets/images/concept/graphic/sports/Graphic Design - Sports 4.jpg'
const sports5 = 'https://mysocialtheory.com/assets/images/concept/graphic/sports/Graphic Design - Sports 5.jpg'

// apparel
const apparelBanner = "https://mysocialtheory.com/assets/images/concept/logo/apparel/Apparel Logo.png"
const apparel1 = 'https://mysocialtheory.com/assets/images/concept/logo/apparel/Logo -Apparel 1.png'
const apparel2 = 'https://mysocialtheory.com/assets/images/concept/logo/apparel/Logo -Apparel 2.png'
const apparel3 = 'https://mysocialtheory.com/assets/images/concept/logo/apparel/Logo -Apparel 3.png'
const apparel4 = 'https://mysocialtheory.com/assets/images/concept/logo/apparel/Logo -Apparel 4.png'
const apparel5 = 'https://mysocialtheory.com/assets/images/concept/logo/apparel/Logo -Apparel 5.png'

// furniture
const furnitureBanner = "https://mysocialtheory.com/assets/images/concept/logo/furniture/Furniture Logo.png"
const furniture1 = 'https://mysocialtheory.com/assets/images/concept/logo/furniture/Logo - Furniture 1.png'
const furniture2 = 'https://mysocialtheory.com/assets/images/concept/logo/furniture/Logo - Furniture 2.png'
const furniture3 = 'https://mysocialtheory.com/assets/images/concept/logo/furniture/Logo - Furniture 3.png'
const furniture4 = 'https://mysocialtheory.com/assets/images/concept/logo/furniture/Logo - Furniture 4.png'
const furniture5 = 'https://mysocialtheory.com/assets/images/concept/logo/furniture/Logo - Furniture 5.png'

// grocery
const groceryBanner = "https://mysocialtheory.com/assets/images/concept/logo/grocery/Grocery Logo.png"
const grocery1 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery/Logos - Grocery 1.png'
const grocery2 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery/Logos - Grocery 2.png'
const grocery3 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery/Logos - Grocery 3.png'
const grocery4 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery/Logos - Grocery 4.png'
const grocery5 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery/Logos - Grocery 5.png'
const grocery6 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery/Logos - Grocery 6.png'

// resto
const restoBanner = "https://mysocialtheory.com/assets/images/concept/logo/resto/Burger Graphic.png"
const resto1 = 'https://mysocialtheory.com/assets/images/concept/logo/resto/Graphic Design - Sports 1.jpg'
const resto2 = 'https://mysocialtheory.com/assets/images/concept/logo/resto/Graphic Design - Sports 2.jpg'
const resto3 = 'https://mysocialtheory.com/assets/images/concept/logo/resto/Graphic Design - Sports 3.jpg'
const resto4 = 'https://mysocialtheory.com/assets/images/concept/logo/resto/Graphic Design - Sports 4.jpg'
const resto5 = 'https://mysocialtheory.com/assets/images/concept/logo/resto/Graphic Design - Sports 5.jpg'

// travel
const travelBanner = "https://mysocialtheory.com/assets/images/concept/logo/travel/Travel Logo.png"
const travel1 = 'https://mysocialtheory.com/assets/images/concept/logo/travel/Logo - Travel 1.png'
const travel2 = 'https://mysocialtheory.com/assets/images/concept/logo/travel/Logo - Travel 2.png'
const travel3 = 'https://mysocialtheory.com/assets/images/concept/logo/travel/Logo - Travel 3.png'
const travel4 = 'https://mysocialtheory.com/assets/images/concept/logo/travel/Logo - Travel 4.png'
const travel5 = 'https://mysocialtheory.com/assets/images/concept/logo/travel/Logo - Travel 5.png'
const travel6 = 'https://mysocialtheory.com/assets/images/concept/logo/travel/Logo - Travel 6.png'

// shoes
const shoesBanner = "https://mysocialtheory.com/assets/images/concept/webDesign/shoes/Shoes Web design.png"
const shoes1 = 'https://mysocialtheory.com/assets/images/concept/webDesign/shoes/139.png'
const shoes2 = 'https://mysocialtheory.com/assets/images/concept/webDesign/shoes/UI UX DESIGN WEB shoes (2).png'
const shoes3 = 'https://mysocialtheory.com/assets/images/concept/webDesign/shoes/UI UX DESIGN WEB shoes (3).png'
const shoes4 = 'https://mysocialtheory.com/assets/images/concept/webDesign/shoes/UI UX DESIGN WEB shoes.png'

// headphones
const headphonesBanner = "https://mysocialtheory.com/assets/images/concept/webDesign/headphones/Headphones Web design.png"
const headphones1 = 'https://mysocialtheory.com/assets/images/concept/webDesign/headphones/1 (3).png'
const headphones2 = 'https://mysocialtheory.com/assets/images/concept/webDesign/headphones/2 (2).png'
const headphones3 = 'https://mysocialtheory.com/assets/images/concept/webDesign/headphones/3 (2).png'
const headphones4 = 'https://mysocialtheory.com/assets/images/concept/webDesign/headphones/4 (2).png'

// packaging
const packagingBanner = "https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Cutouts Graphic.png"
const packaging1 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Packaging Cutouts 1.png'
const packaging2 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Packaging Cutouts 2.png'
const packaging3 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Packaging Cutouts 3.png'
const packaging4 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Packaging Cutouts 4.png'
const packaging5 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Packaging Cutouts 5.png'
const packaging6 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging/Packaging Cutouts 6.png'

// alchohol
const alchoholBanner = "https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Alcohol Graphic.png"
const alchohol1 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 1.png'
const alchohol2 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 2.png'
const alchohol3 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 3.png'
const alchohol4 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 4.png'
const alchohol5 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 5.png'
const alchohol6 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 6.png'
const alchohol7 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 7 (2).png'
const alchohol8 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 7.png'
const alchohol9 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Bottle 8.png'
const alchohol10 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Can 1.png'
const alchohol11 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Can 2.png'
const alchohol12 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Can 3.png'
const alchohol13 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Can 4.png'
const alchohol14 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Beer Can 5.png'
const alchohol15 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Gin 1.png'
const alchohol16 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Gin 2.png'
const alchohol17 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Gin 3.png'
const alchohol18 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Gin 4.png'
const alchohol19 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Whiskey 1.png'
const alchohol20 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Whiskey 2.png'
const alchohol21 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Whiskey 3.png'
const alchohol22 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Whiskey 4.png'
const alchohol23 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Wine 2.png'
const alchohol24 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Wine 3.png'
const alchohol25 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Wine 4.png'
const alchohol26 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol/Packaging Wine 5.png'


// beverages
const beveragesBanner = "https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Beverages Graphic.png"
const beverages1 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Coffee 1.png'
const beverages2 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Coffee 2.png'
const beverages3 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Coffee 3.png'
const beverages4 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Coffee 4.png'
const beverages5 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Coffee 5.png'
const beverages6 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 1.png'
const beverages7 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 2.png'
const beverages8 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 3.png'
const beverages9 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 4.png'
const beverages10 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 5.png'
const beverages11 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 6.png'
const beverages12 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages/Packaging Tea 7.png'

// candles
const candlesBanner = "https://mysocialtheory.com/assets/images/concept/branding-packging/candles/Candles Graphic.png"
const candles1 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/candles/Packaging Candle 1.png'
const candles2 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/candles/Packaging Candle 2.png'

// sticks
const sticksBanner = "https://mysocialtheory.com/assets/images/concept/branding-packging/sticks/Incent Graphic.png"
const sticks1 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/sticks/Packaging Incense Stick 1.png'
const sticks2 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/sticks/Packaging Incense Stick 2.png'
const sticks3 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/sticks/Packaging Incense Stick 3.png'
const sticks4 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/sticks/Packaging Incense Stick 4.png'

// music
const musicBanner = "https://mysocialtheory.com/assets/images/concept/ui-ux/music/Music UI UX.png"
const music1 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/music/UI UX DESIGN MUSIC.png'
const music2 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/music/UI UX DESIGN MUSIC (2).png'
const music3 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/music/UI UX DESIGN MUSIC (3).png'
const music4 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/music/UI UX DESIGN MUSIC (4).png'

// groceryUIUX
const groceryUIUXBanner = "https://mysocialtheory.com/assets/images/concept/ui-ux/groceryUIUX/Grocery UI UX.png"
const groceryUIUX1 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/groceryUIUX/UI UX DESIGN GROCERY.png'
const groceryUIUX2 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/groceryUIUX/UI UX DESIGN GROCERY (2).png'
const groceryUIUX3 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/groceryUIUX/UI UX DESIGN GROCERY (3).png'
const groceryUIUX4 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/groceryUIUX/UI UX DESIGN GROCERY (4).png'

// travelUIUX
const travelUIUXBanner = "https://mysocialtheory.com/assets/images/concept/ui-ux/travelUIUX/Travel UI UX.png"
const travelUIUX1 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/travelUIUX/UI UX DESIGN TRAVEL.png'
const travelUIUX2 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/travelUIUX/UI UX DESIGN TRAVEL (2).png'
const travelUIUX3 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/travelUIUX/UI UX DESIGN TRAVEL (3).png'
const travelUIUX4 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/travelUIUX/UI UX DESIGN TRAVEL (4).png'

// pizza
const pizzaBanner = "https://mysocialtheory.com/assets/images/concept/ui-ux/pizza/Pizza UI UX.png"
const pizza1 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/pizza/UI UX DESIGN PIZZA.png'
const pizza2 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/pizza/UI UX DESIGN PIZZA (2).png'
const pizza3 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/pizza/UI UX DESIGN PIZZA (3).png'
const pizza4 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/pizza/UI UX DESIGN PIZZA (4).png'


const data = {
    "basha-food": {
        bannerImg: bashaFoodBanner,
        companyInfo: "Basha Foods International is a halal ethnic grocery store serving Calgary, Alberta since 2010. With more than 100 years of combined experience in this industry, Basha team is highly dedicated to giving not only the best products, selection of international foods, and prices possible, but also unparalleled service.",
        problem: ["Before Social Theory came into picture, the brand was grappling with outdated graphic designs and an unintuitive website, undermining user experience and overall digital presence. This problem is compounded by a notable lack of customer interactions. The social media footprint, specifically on Instagram, is negligible with no engagement, infrequent posting and a declining follower count, all due to a weak profile.", "It also suffered from a lack of in-store marketing and promotional activities, decreasing our visibility and sales. The customer enrollment strategy was ineffective, hindering repeat business and customer loyalty. It was missing out on the high ROI of email marketing, indicating no streamlined marketing strategy."],
        problemImg: [bashafoodProb1, bashafoodProb2],
        solutions: ["To address the aforementioned issues and enhance the brand’s digital presence, we employed modern, interactive and simplistic graphic designs using tools like Canva, Adobe Photoshop and Illustrator, with an aim to create visually appealing and user-friendly designs."],
        logoDesign: [],
        logoImg: [],
        images: [bashafood1, bashafood2, bashafood3, bashafood4, bashafood5, bashafood6, bashafood7, bashafood8, bashafood9, bashafood10, bashafood11, bashafood12, bashafood13],
        extraText: ["Email marketing, utilising platforms like Mailchimp, is initiated to reach out to our existing customer base and potential leads, thereby boosting engagement and brand recall. Additionally, we have designed new business cards and customer enrollment/membership forms to add more people to the existing list of customers.", "A complete and updated business profile is maintained across all platforms to ensure consistency in our branding and messaging. We prioritise prompt responses to customer inquiries on any social media platform, thereby improving customer service and engagement. Activities such as contests and giveaways are conducted more frequently to keep the audience involved and interested."],
        card: [card1, card2],
        points: ["Facebook reach - 1.2 Million, Increased by 551% since take over", "Facebook Increase in number of followers - increase by 1100%", "Instagram reach - 883K, Increased by 808% since take over", "Annual Return on Digital Marketing Investment is 821%", "Increase in impressions to 13.6 Million", "More than 100,000+ Profile Visits", "Instagram Increase in Number of followers- Increase by 807%", "Driving everyday Avg 800 customers footfalls in store."]
    },
    "wsj": {
        bannerImg: wsjBanner,
        companyInfo: "The primary focus of White Spot Janitorial Services Ltd. is to offer a thorough and complete professional janitorial service. It is one of the best and biggest cleaning companies in Calgary AB. The key to their success is based on the ability to forge strong connections with their clients and staff. Their main market is commercial cleaning services.",
        problem: ["White Spot Janitorial was facing challenges with their online presence. Their social media platforms displayed subpar graphic design, resulting in an unprofessional appearance. Being a market leader in its segment, this affected its Brand Image to a great extent.", "Additionally, lack of communication and misunderstanding with their social media manager led to poor online performance. This indeed, led to a dearth of lead generation and minimal online customer engagement.", "Furthermore, the infrequent posting on social media hindered their ability to stay connected with their audience. Their online business profile was weak, affecting their credibility. Overall, these issues were hampering their online success and needed to be addressed immediately for improved results"],
        problemImg: [wsjProb1, wsjProb2],
        solutions: ["To address the challenges of White Spot Janitorial effectively, a comprehensive set of solutions were formulated. By using advanced graphic design tools such as Canva, Adobe Photoshop and Illustrator, we uplifted their visual content quality, resulting in a more polished and professional brand image.", "Swift and attentive responses to customer inquiries has enhanced the customer service experience, fostering trust and satisfaction. Implementing a consistent social media posting schedule has led to greater engagement with potential clients, thereby boosting online presence.", "The strategic approach to content creation that encompasses informative, trending, relatable and meme-based posts, along with diverse types of reels has captured and retained audience interest.", "Moreover, Integrating lead generation forms has facilitated the acquisition of valuable customer data for targeted marketing efforts. Furthermore, a revamped online business profile will bolster the company's credibility and reputation.", "By adopting these solutions, the White Spot Janitorial has seen a significant improvement in their overall brand image, customer engagement, and business performance."],
        logoDesign: [],
        logoImg: [],
        images: [wsj1, wsj2, wsj3, wsj4, wsj5, wsj6, wsj7, wsj8, wsj9, wsj10, wsj11, wsj12, wsj13, wsj14, wsj15, wsj16, wsj17, wsj18, wsj19, wsj20, wsj21, wsj22, wsj23, wsj24, wsj25, wsj26, wsj27, wsj28, wsj29, wsj30],
        extraText: [],
        card: [],
        points: ["Facebook reach - 168K, Increased by 679.3% since takeover", "Instagram reach - 101k, Increased by 845.7% since takeover", "Increase in impressions to 350k.", "More than 10k Profile Visits, increased by 492% since takeover."]
    },
    "city-slice-pizzeria": {
        bannerImg: citySliceBanner,
        companyInfo: "City Slice Pizzeria prides itself at being a family owned restaurant and one of those locally loved restaurants. They are a one stop pizza place that believes in fast service and giving its customers the best and unique customer service experience. Their core idea is to serve Pizzas with an Indian touch to it. City Slice is Truly Desi!",
        problem: ["City Slice pizzeria, despite having a quality product, suffered from a conspicuous absence of online presence. This digital void has further compounded the issue of insufficient customer interactions. While the product's inherent value could shine in the market, it remained overshadowed due to a stark absence of marketing initiatives. Moreover, the in-store experience, too, lacked promotional efforts that could otherwise draw attention and drive sales. Furthermore, the brand's visual representation seemed trapped in a bygone era with outdated designs. This inconsistency is further evident in the lack of a cohesive pattern or theme to their designs, making the brand's identity feel scattered and undefined."],
        problemImg: [],
        solutions: ["The solution we offered was a simple and strategic change in the entire outline of its digital presence. The restaurant is located at the edge of its town by the express highway. This challenging yet strategic location helped us understand the exact target market. The unique cuisine also presented an opportunity to showcase the value in the product offerings. Tweak in design patterns, frequent posting, and improvement in overall feel of the brand. Better in-store presence with poster designs and bold representations of discounts and other offers."],
        logoDesign: [],
        logoImg: [],
        images: [citySlice1, citySlice2, citySlice3, citySlice4, citySlice5, citySlice6, citySlice7, citySlice8, citySlice9, citySlice10, citySlice11, citySlice12],
        extraText: [],
        card: [],
        points: []
    },
    "k-enterprises": {
        bannerImg: kEnterprisesBanner,
        companyInfo: "K enterprises is an outdoor advertising company based in Mumbai. They operate in various other cities such as Pune, Delhi and Rajkot. It was started in 1992. They are known in this market spectrum for its innovative outdoor strategies and own various billboards in prime locations of the mentioned cities. Their main focus is the democratisation of outdoor media and make it accessible for small to medium companies and not just large corporations.",
        problem: ["K Enterprises, a recognized leader in Outdoor Advertising, has cemented its reputation through impactful banners nationwide. Their legacy stands synonymous with dependability and a commendable return on investment. However, in an increasingly digital age, K Enterprises lags behind with no online presence or website. Presently, they operate solely offline, accessible only through phone calls or office visits. This absence from the digital realm restricts their engagement with prospective clients who primarily search for services online. Additionally, their antiquated logo design fails to encapsulate the essence of K Enterprises, potentially diminishing their brand image."],
        problemImg: [],
        solutions: ["K Enterprises, a prominent name in outdoor advertising, needs to embrace the digital age to stay competitive. A comprehensive solution involves the creation of a user-friendly, SEO-optimised website that showcases their services and successes. To bolster their online visibility, it's essential to establish profiles on key social media platforms and engage in digital advertising. A crucial step is a branding refresh, beginning with a modern logo redesign that resonates with today's market. Encouraging online reviews and maintaining updated content can further position K Enterprises as a forward-thinking leader in their industry. We have also created some engaging and modern graphic designs for the social media and website that match with its overall brand."],
        logoDesign: ["The dominant shape is a billboard, which signifies the company's primary business in outdoor advertising. It's rectangular, with slightly curved edges to give it a modern and streamlined appearance.", `At the heart of this billboard is a bold, uppercase "K." The letter should be in a font that conveys strength, reliability, and modernity. It stands as a testament to the company's name and identity.`, `A blend of neutral colour deep blue for the billboard framework would evoke a sense of professionalism. The "K" is portrayed through various colours to retain some essence of its previous logo. This helps the company show its premium quality service and trustworthiness.`],
        logoImg: [kEnterprisesLogo1],
        images: [kenterprises1, kenterprises2, kenterprises3, kenterprises4, kenterprises5, kenterprises6, kenterprises7, kenterprises8, kenterprises9, kenterprises10],
        extraText: [],
        card: [],
        points: []
    },
    "fresh-slice-kamloops": {
        bannerImg: freshsliceBanner,
        companyInfo: `Freshslice Pizza (Kamloops) is a Canadian pizza franchise chain that specialises in offering affordable, healthy, and freshly made pizzas. The company is known for its "pizza by the slice" concept, where customers can purchase individual slices of pizza with a variety of toppings.`,
        problem: ["In a small city where franchise location meets independent marketing, the challenges are evident. With limited awareness and scarce marketing knowledge, they were grappling with the need to establish their presence within the community digitally. The central obstacle was their minimal advertising budget leading to the absence of in-store marketing materials and customer interactions. Compounded by the absence of creative graphic designs and consistent social media posts, they failed to reach their target customer base. Addressing these hurdles was crucial for enhancing the brand visibility and engaging with the local population effectively."],
        problemImg: [],
        solutions: ["To address the aforementioned issues and enhance the brand’s digital presence, we integrated in-store promotional materials like eye catching flyers offering “Complimentary slice upon following on social media platforms: Facebook/Instagram for a limited time.” served as clever incentives that not only raised awareness but also encouraged word of mouth referrals. Collaborating with other social media accounts for joint giveaways provided a unique opportunity for tapping into new networks of potential customers. Improving the quality of posts with creative captions, visually appealing designs and frequent online posting, boosted the customer interaction. The key was the prudent budget allocation, by channelling the limited resources optimally to achieve maximum impact. By investing wisely and creatively, the business can navigate its constraints, gradually carving a niche in the market and building a loyal customer base."],
        logoDesign: [],
        logoImg: [],
        images: [freshslice1, freshslice2, freshslice3, freshslice4, freshslice5, freshslice6],
        extraText: [],
        card: [],
        points: ["Facebook reach - 95k+, Increased by 207.90% since take over", "Instagram reach - 83K+, Increased by 173% since take over", "Facebook Increase in number of followers - increase by 170%", "Instagram Increase in Number of followers- Increase by 100%", "Increase in impressions to 200k", "Increase in sales by 15%"]
    },
    "basha-vapes": {
        bannerImg: bashavapesBanner,
        companyInfo: "Basha Vapes is a new vaping brand launched in Calgary, AB. It sells various different brands of Vaping products. Basha Vapes ensures commitment to quality, innovation, and responsible vaping practices and also aims to soon emerge as a leading vaping brand in Calgary. Their mission is to provide adult smokers with a safer alternative while delivering a diverse range of top-notch vaping products and experiences.",
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: ["​​The primary shape of the logo is a shield, which represents unity, wholeness, and strength.. This shape is versatile for branding across various platforms and packaging."],
        logoImg: [],
        images: [bashavapes1, bashavapes2, bashavapes3, bashavapes4, bashavapes5, bashavapes6, bashavapes7, bashavapes8, bashavapes9, bashavapes10],
        extraText: [],
        card: [],
        points: []
    },
    "bedi-finance": {
        bannerImg: bedifinanceBanner,
        companyInfo: "Bedi Financial Services is a premier financial services and accounting company based in Surrey, British Columbia and Calgary, Alberta dedicated to helping individuals and businesses achieve their financial goals. This is a relatively new client, we do not have any results or much information to provide currently.",
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [bedifinance1, bedifinance2, bedifinance3, bedifinance4, bedifinance5, bedifinance6],
        extraText: [],
        card: [],
        points: []
    },
    "burger": {
        bannerImg: burgerBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [burger1, burger2, burger3, burger4, burger5],
        extraText: [],
        card: [],
        points: []
    },
    "bread": {
        bannerImg: breadBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [bread1, bread2, bread3, bread4, bread5],
        extraText: [],
        card: [],
        points: []
    },
    "electronics": {
        bannerImg: electronicsBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [electronics1, electronics2, electronics3, electronics4, electronics5],
        extraText: [],
        card: [],
        points: []
    },
    "fashion": {
        bannerImg: fashionBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [fashion1, fashion2, fashion3, fashion4, fashion5, fashion6, fashion7, fashion8, fashion9],
        extraText: [],
        card: [],
        points: []
    },
    "indianResto": {
        bannerImg: indianRestoBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [indianResto1, indianResto2, indianResto3, indianResto4, indianResto5, indianResto6],
        extraText: [],
        card: [],
        points: []
    },
    "housing": {
        bannerImg: housingBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [housing1, housing2, housing3, housing4, housing5],
        extraText: [],
        card: [],
        points: []
    },
    "sports": {
        bannerImg: sportsBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [sports1, sports2, sports3, sports4, sports5],
        extraText: [],
        card: [],
        points: []
    },
    "apparel": {
        bannerImg: apparelBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [apparel1, apparel2, apparel3, apparel4, apparel5],
        extraText: [],
        card: [],
        points: []
    },
    "furniture": {
        bannerImg: furnitureBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [furniture1, furniture2, furniture3, furniture4, furniture5],
        extraText: [],
        card: [],
        points: []
    },
    "grocery": {
        bannerImg: groceryBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [grocery1, grocery2, grocery3, grocery4, grocery5, grocery6],
        extraText: [],
        card: [],
        points: []
    },
    "resto": {
        bannerImg: restoBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [resto1, resto2, resto3, resto4, resto5],
        extraText: [],
        card: [],
        points: []
    },
    "travel": {
        bannerImg: travelBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [travel1, travel2, travel3, travel4, travel5, travel6],
        extraText: [],
        card: [],
        points: []
    },
    "shoes": {
        bannerImg: shoesBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [shoes1, shoes2, shoes3, shoes4],
        extraText: [],
        card: [],
        points: []
    },
    "headphones": {
        bannerImg: headphonesBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [headphones1, headphones2, headphones3, headphones4],
        extraText: [],
        card: [],
        points: []
    },
    "packaging": {
        bannerImg: packagingBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [packaging1, packaging2, packaging3, packaging4],
        extraText: [],
        card: [],
        points: []
    },
    "alchohol": {
        bannerImg: alchoholBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [alchohol1, alchohol2, alchohol3, alchohol4, alchohol5, alchohol6, alchohol7, alchohol8, alchohol9, alchohol10, alchohol11, alchohol12, alchohol13, alchohol14, alchohol15, alchohol16, alchohol17, alchohol18, alchohol19, alchohol20, alchohol21, alchohol22, alchohol23, alchohol24, alchohol25, alchohol26],
        extraText: [],
        card: [],
        points: []
    },
    "beverages": {
        bannerImg: beveragesBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [beverages1, beverages2, beverages3, beverages4, beverages5, beverages6, beverages7, beverages8, beverages9, beverages10, beverages11, beverages12],
        extraText: [],
        card: [],
        points: []
    },
    "candles": {
        bannerImg: candlesBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [candles1, candles2],
        extraText: [],
        card: [],
        points: []
    },
    "sticks": {
        bannerImg: sticksBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [sticks1, sticks2, sticks3, sticks4],
        extraText: [],
        card: [],
        points: []
    },
    "music": {
        bannerImg: musicBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [music1, music2, music3, music4],
        extraText: [],
        card: [],
        points: []
    },
    "groceryUIUX": {
        bannerImg: groceryUIUXBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [groceryUIUX1, groceryUIUX2, groceryUIUX3, groceryUIUX4],
        extraText: [],
        card: [],
        points: []
    },
    "travelUIUX": {
        bannerImg: travelUIUXBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [travelUIUX1, travelUIUX2, travelUIUX3, travelUIUX4],
        extraText: [],
        card: [],
        points: []
    },
    "pizza": {
        bannerImg: pizzaBanner,
        companyInfo: null,
        problem: [],
        problemImg: [],
        solutions: [],
        logoDesign: [],
        logoImg: [],
        images: [pizza1, pizza2, pizza3, pizza4],
        extraText: [],
        card: [],
        points: []
    },
}

export default data