import { TypeAnimation } from "react-type-animation";
import Background from "../Components/Common/Background";
import Journey from "../Components/OurJourney/Journey";
import Fade from 'react-reveal/Fade'

const aboutus = "https://mysocialtheory.com/assets/images/aboutus.png";

const Aboutus = () => {
  return (
    <Background backgroundColor="#0d0d0d">
      <div className="row mx-0 p-4 ps-md-0 py-md-0 pe-md-4 pe-xl-5 overflow-x-hidden">
        <Fade left>
          <div className="position-relative col-md-6 px-0">
            <TypeAnimation
              className="aboutus_heading"
              sequence={["About Us"]}
              wrapper="p"
              speed={0.5}
            />
            <div className="aboutus_right_para d-block d-md-none">
              <p>
                We believe in the mindset of work of art. If you see everything
                from the perspective of an artist somehow things always come to
                light.
              </p>
            </div>
            <img
              className="aboutus_img w-100 h-auto"
              src={aboutus}
              alt="about-us"
            />
            <div className="aboutus_left_para">
              <p className="mb-0">
                Our amazing clients choose us for our skills and a deeper
                understanding in the means of designs and growth. We love the
                beauty that the numbers bring. We go deeper in every aspect of
                their business from giving it a new identity to taking it to the
                next level by delivering out of the ordinary content and design.
                They like us because we go against everything that is boring.
              </p>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className="aboutus_right_para d-none d-md-flex col-md-6 align-items-center justify-content-end">
            <p className="mb-0">
              We believe in the mindset of work of art. If you see everything from
              the perspective of an artist somehow things always come to light.
            </p>
          </div>
        </Fade>
      </div>
      <Journey />
    </Background>
  );
};

export default Aboutus;
