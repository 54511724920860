const Background = ({ backgroundColor, children }) => {
  return (
    <div style={{ backgroundColor }} className="background">
      <span className="lines"></span>
      <span className="lines"></span>
      <span className="lines"></span>
      <span className="lines"></span>
      <span className="lines"></span>
      <span className="lines"></span>
      <span className="lines"></span>
      {children}
    </div>
  );
};

export default Background;
