import styles from '../../assets/css/blogDetails.module.scss'

const img1 = 'https://mysocialtheory.com/assets/images/blog/blog2-1.jpg'
const img2 = 'https://mysocialtheory.com/assets/images/blog/blog2-2.png'
const img3 = 'https://mysocialtheory.com/assets/images/blog/blog2-3.png'
const img4 = 'https://mysocialtheory.com/assets/images/blog/blog2-4.png'
const img5 = 'https://mysocialtheory.com/assets/images/blog/blog2-5.png'


const Blog2 = () => {
    return (
        <div className={styles.main}>
            <h3 className={styles.heading}>Why Trendy Graphic Designs are a Game-Changer for Your Business & Social Media</h3>
            <div className={styles.contents}>
                <p>Welcome to the world of colours, patterns, and designs! If you've ever wondered why some Instagram posts get more likes than others or why certain websites just feel more inviting, chances are, graphic design is the unsung hero behind the scenes. </p>
                <p>Let's dive into why having up-to-date and catchy graphic designs is the secret sauce for any thriving business and social media platform.</p>
                <div className={styles.imgContainer}>
                    <img src={img1} alt="study" />
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>1. First Impressions Matter</h5>
                <div className={styles.contents}>
                    <p>Think about it. You've got mere seconds to grab your audience's attention. An eye-catching design can be the difference between someone scrolling past your post or stopping to see what you're all about. With trendy graphics, you're not just following the crowd - you're making a statement that your brand is current and knows what's up!</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>2. Evoking Emotions</h5>
                <div className={styles.contents}>
                    <p>Emotions play a pivotal role in consumer decision-making. Graphic design has the power to evoke emotions that resonate with your target audience. Whether it's humor, nostalgia, empathy or excitement, a well-crafted design can tap into these emotions and create a lasting connection between your brand and its customers.</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>3. Boost Your Brand Identity</h5>
                <div className={styles.contents}>
                    <p>Consistency in design across all your platforms solidifies your brand identity. When your designs are in sync with market trends, it communicates a dynamic, evolving, and forward-thinking brand. It's not just about looking good; it's about showcasing your brand's personality.</p>
                    <div className={styles.imgContainer}>
                        <img src={img2} alt="brand-image" />
                    </div>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>4. Increase Engagement on Social Media</h5>
                <div className={styles.contents}>
                    <p>A funky, fresh design can make your content pop on someone's feed. If your graphics are on-point, people are more likely to share, comment, and engage with your content. And let's be real, who doesn't want their notifications blowing up with likes and shares?</p>
                    <div className={styles.imgContainer}>
                        <img src={img3} alt="social-media" />
                    </div>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>5. Drive More Sales</h5>
                <div className={styles.contents}>
                    <p>Believe it or not, good design can directly influence purchasing decisions. If your product looks appealing, customers are more likely to hit that 'Add to Cart' button. It's psychology, baby!</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>6. Stand Out from the Competition</h5>
                <div className={styles.contents}>
                    <p>Every business out there is vying for attention. By embracing the latest design trends, you're giving yourself an edge over competitors who might be stuck in the past. It's like showing up to a party in the hottest outfit - people are gonna notice!</p>
                    <div className={styles.imgContainer}>
                        <img src={img4} alt="crowd" />
                    </div>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>7. Build Trust and Credibility</h5>
                <div className={styles.contents}>
                    <p>When your graphics are sleek and professional, it reflects positively on your business. It tells your audience that you invest time and resources into presenting your brand in the best light. And if you care that much about your appearance, surely your products and services must be top-notch too, right?</p>
                </div>
            </div>
            <div className={styles.subHead}>
                <h5>8. Viral Potential</h5>
                <div className={styles.contents}>
                    <p>In the age of social media, content can spread like wildfire if it resonates with users. Clever graphic designs that are witty, relatable or shareable can increase the likelihood of your content going viral. Memes, for instance, often rely on smart graphic design to convey humour or commentary, making them highly shareable across platforms.</p>
                    <div className={styles.imgContainer}>
                        <img src={img5} alt="crowd" />
                    </div>
                    <p>In the ever-evolving digital landscape, staying on top of design trends isn't just a nice-to-have; it's a must! Whether it's for your business website or your next viral social media post, rocking the right graphics can make all the difference.</p>
                </div>
            </div>
        </div>

    )
}

export default Blog2