import * as z from 'zod'

const contactUSSchema = z.object({
    name: z.string().nonempty("Name is required"),
    email: z.string().nonempty("Email is required"),
    subject: z.string().nonempty("Subject is required"),
    message: z.string().nonempty("Message is required"),
    phone: z.string().optional(),
})

export { contactUSSchema }