import Background from '../Components/Common/Background'
import { FiMapPin } from 'react-icons/fi';
import { MdCall, MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Fade, Bounce } from 'react-reveal'
import { useForm } from 'react-hook-form';
import { contactUSSchema } from '../lib/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';

const ContactUs = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: zodResolver(contactUSSchema),
        defaultValues: {
            name: "",
            email: "",
            subject: "",
            message: "",
            phone: ""
        }
    })

    const onSubmit = async (values) => {
        try {
            const formData = new FormData()

            Object.entries(values).forEach(([key, values]) => formData.append(key, values))

            const res = await fetch("https://client.appmania.co.in/socialtheory/mail-contact-us.php", {
                method: "POST",
                body: formData
            })

            const data = await res.json()

            if (data.ResponseCode === 1) {
                toast.success(data.ResponseMsg)
                reset()
            } else {
                toast.error(data.ResponseMsg)
            }
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error)
        }
    }

    return (
        <Background backgroundColor="#0d0d0d">
            <section className='maps'>
                <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.9749275322183!2d-113.95702372300795!3d51.164251635713036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716179713e22f1%3A0x287d70e871a0a5d!2s73%20Skyview%20Point%20Crescent%20NE%2C%20Calgary%2C%20AB%2C%20Canada!5e0!3m2!1sen!2sin!4v1695722765593!5m2!1sen!2sin" width="100%" height="324" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </section>
            <section className="contactus_form_section overflow-x-hidden">
                <div className="container">
                    <div className="row">
                        <Fade left>
                            <div className="col-12 col-lg-4">
                                <div className="contactus_box">
                                    <div className="contactus_heading">
                                        <h3>Get a free quote now</h3>
                                        <p>Ready to boost your online success? Get a free estimate from our digital marketing experts and start growing your business today!</p>
                                    </div>
                                    <div className="contactus_box_info">
                                        <ul>
                                            <li>
                                                <div className='d-flex align-items-center'>
                                                    <MdCall className='company_socical_ico' />
                                                    <div>
                                                        <h6>Phone</h6>
                                                        <span>+1 (250) 879 2165</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='d-flex align-items-center'>
                                                    <MdEmail className='company_socical_ico' />
                                                    <div>
                                                        <h6>Email</h6>
                                                        <Link to={"mailto:info@mysocialtheory.com"}>info@mysocialtheory.com</Link>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='d-flex align-items-center'>
                                                    <FiMapPin className='company_socical_ico' />
                                                    <div>
                                                        <h6>Location</h6>
                                                        <span>73 Skyview Point Crescent NE,
                                                            <br />
                                                            Calgary AB T3N 1S9</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <div className="col-12 col-lg-8">
                            <form className='contactUs_form' onSubmit={handleSubmit(onSubmit)}>
                                <Bounce right cascade>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form_control">
                                                <label htmlFor="name">Name*</label>
                                                <input type="text" id='name' name='name' placeholder='Name' required autocomplete="off"  {...register("name")} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_control">
                                                <label htmlFor="email">Email*</label>
                                                <input type="email" id='email' name='email' placeholder='Email' required autocomplete="off"  {...register("email")} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_control">
                                                <label htmlFor="phone">phone number*</label>
                                                <input type="number" id='phone' name='phone' placeholder='Phone' required autocomplete="off"  {...register("phone")} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_control">
                                                <label htmlFor="subject">subject*</label>
                                                <input type="text" name='subject' placeholder='subject' required autocomplete="off"  {...register("subject")} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form_control">
                                                <label htmlFor="message">message*</label>
                                                <textarea name="message" id='message' cols="30" rows="4" required autocomplete="off" {...register("message")}></textarea>
                                            </div>
                                        </div>
                                        <div className="submit_btn">
                                            <button type='submit'>submit now</button>
                                        </div>
                                    </div>
                                </Bounce>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Background>
    )
}

export default ContactUs