import { CHILD, CONTAINER } from "../../Constants/FramerVariant";
import styles from "./Hero.module.scss";
import { motion } from "framer-motion";

const Tab3 = ({ activeTab }) => {
  return (
    <div className={styles.heading}>
      <h1
        key={activeTab}
        className="text-center"
      >
        <span className={styles.blur + " " + styles.tab2gradient}>FUELING STARTUPS</span>
      </h1>
      <h2
        key={activeTab}
      >
        <span className={styles.blur}>STRONG</span> <span className={styles.blur}>START</span> <span className={styles.blur}>TO</span> <span className={styles.blur}>SOAR</span> <span className={styles.blur}>HIGH</span>
      </h2>
      <motion.p
        variants={CONTAINER}
        initial="hidden"
        animate="visible"
        className={`${styles.tab3_para} d-flex flex-wrap justify-content-center gap-1`}
      >
        {"We’ve been there, we’re doing it too with you. We get it, it gets tough. It’s our ambition to help Start-ups jump to stardoms. Get in touch with us, we’d love to be part of your digital success. We’d make sure you do not fit in so you can stand out."
          .split(" ")
          .map((word, index) => (
            <motion.span key={index} variants={CHILD}>
              {word}
            </motion.span>
          ))}
      </motion.p>
    </div>
  );
};

export default Tab3;
