const teamData = {
    "kaushil-shah": {
        name: "Kaushil Shah",
        role: "Co-founder, COO",
        title: "Entrepreneur & Digital Dynamo with an Appetite for Adventure!",
        about1: "This London-educated dynamo has a Masters in International Business and Marketing under his belt along with a diploma in photography too and, wait for it… He also founded Social Theory. Yep, he's the complete package.",
        about2: "With 7 years diving deep into the digital domain and a chapter in outdoor advertising - Kaushil has got a double life. Yep, he's the guy who can blend the online and real world seamlessly, creating campaigns that'll make you look twice. He is a storyteller along with his passion for writing and reading. He makes sure your brand’s voice is heard.",
        about3: "Here's some insider info: Beyond making our digital stuff look downright dashing, Kaushil is also our in-house shutterbug. So, whether it's capturing candid team moments or getting that perfect shot for a campaign, his photography skills are always on point.",
        about4: "But let’s get to the fun bits. When the screens go off, you’ll likely spot Kaushil packing his bags for his next travel escapade. As our go-to globe-trotter, he’s all about discovering unseen spots, mountains, different cuisines and of course, getting on a safari and doing some wildlife photography!",
        footTitle: "Wanna chat about the latest marketing trends or the best food joints in town? Hit up Abhijeet!",
        ig: "",
        mail: ""
    },
    "abhijeet-shah": {
        name: "Abhijeet Shah",
        role: "Founder, CEO",
        title: "The Campaign Commander with a Binge-Worthy Palette of Designs!",
        about1: "Meet the heart and soul behind our company, Abhijeet! After bagging his Masters in Marketing from TRU in Kamloops, he didn't just hop into the digital world - he founded this very space where creativity meets strategy. With 7 years (and counting) of mastering the art of Digital and Social Media marketing, he's got this arena dialled in.",
        about2: "But let's dish some inside scoop: Abhijeet isn’t just the brains behind the operation; he’s our go-to fixer-upper. Any hiccups? Abhijeet's on it.",
        about3: "But here's a secret: Abhijeet's not just a marketing maestro; he's also our in-house problem-solver. Got a digital dilemma? Abhijeet's your man.",
        about4: "Now, when he's off the clock, you can find him binge-watching the latest series, scouting out the city's coolest spots, or chowing down on some scrumptious food. Who knew a passion for pixels could pair so well with a love for all things delicious?",
        footTitle: "Wanna chat about the latest marketing trends or the best food joints in town? Hit up Abhijeet!",
        ig: "",
        mail: ""
    },
    "jasneet-kaur": {
        name: "Jasneet Kaur",
        role: "Marketing and Design Specialist",
        title: "Graphics & Content Queen with a Melody in her Heart",
        about1: "Step right up and meet Jasneet (Better also known as Dolcie), our in-house digital dreamer and design dynamo! Rocking a BBA degree, she’s been making waves in the digital marketing world for a solid 3 years now. And talk about design? She's got an eye for it that can turn even the blandest of ideas into masterpieces.",
        about2: "But here's the real tea: Jasneet’s stubborn streak? It’s golden. It’s the kind that pushes her to perfect every project down to the tiniest pixel. If you're looking for out-of-the-box brilliance, she's your go-to gal.",
        about3: "Off the clock, Jasneet has a whirlwind of passions. She's got a camera forever in hand, capturing life's candid moments. A travel enthusiast, she's always got a new destination on her radar. Oh, and did we mention that she can Sing too! Yep, armed with her trusty guitar, she's graced many a stage, leaving audiences clapping.",
        about4: "And if there's one thing she loves as much as a good tune, it's tantalising her taste buds. Jasneet is a bona fide foodie, always on the hunt for the next delicious dish.",
        footTitle: "Fancy a brainstorm over bites or a duet jam session? Get in touch with Jasneet!",
        ig: "",
        mail: ""
    }
}

export default teamData