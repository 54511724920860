import { useParams } from "react-router-dom"
import styles from '../assets/css/clientDesign.module.scss'
import data from '../Constants/ClientDesign'

const bashavapesLogo = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashavapesLogo.png'

const ClientDesignDetails = () => {
    const { name } = useParams()

    const tickIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_930_1331)">
            <path d="M17.5004 9.28683V22.4C17.5004 22.5857 17.4266 22.7637 17.2954 22.895C17.1641 23.0263 16.986 23.1 16.8004 23.1H1.60039C1.41474 23.1 1.23669 23.0263 1.10542 22.895C0.97414 22.7637 0.900391 22.5857 0.900391 22.4V7.2C0.900391 7.01435 0.97414 6.8363 1.10542 6.70503C1.23669 6.57375 1.41474 6.5 1.60039 6.5H16.8C16.8439 6.50009 16.8876 6.50423 16.9304 6.5123L17.5004 9.28683Z" stroke="white" />
            <path d="M10.0001 18.8009C9.68186 18.8008 9.37669 18.6744 9.1517 18.4493L5.5517 14.8493C5.33311 14.623 5.21216 14.3199 5.21489 14.0052C5.21762 13.6906 5.34383 13.3896 5.56632 13.1671C5.78881 12.9446 6.08978 12.8184 6.40442 12.8157C6.71905 12.813 7.02218 12.9339 7.2485 13.1525L9.8857 15.7897L21.4517 0.865711C21.6471 0.615463 21.9338 0.452868 22.2488 0.413541C22.5639 0.374215 22.8817 0.461362 23.1327 0.655895C23.3836 0.850428 23.5472 1.13649 23.5877 1.45142C23.6281 1.76635 23.5421 2.08447 23.3485 2.33611L10.9485 18.3361C10.8442 18.4707 10.7124 18.5815 10.5619 18.6613C10.4114 18.741 10.2457 18.7878 10.0757 18.7985C10.0506 18.8001 10.0254 18.8009 10.0001 18.8009Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_930_1331">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

    return (
        <div className={`${styles.detailMain} px-2 px-md-4`}>
            <div className={styles.bannerContainer}>
                <img className="w-100 h-auto" src={data[name].bannerImg} alt="bannerImg" />
                <div className={styles.overlay}></div>
            </div>
            {data[name].companyInfo && <div className={styles.infoBox}>
                <h5 className={styles.heading}>Company Information</h5>
                <div className={styles.text}>
                    <p>{data[name].companyInfo}</p>
                </div>
            </div>}
            {data[name].problem.length > 0
                && <div className={styles.infoBox}>
                    <h5 className={styles.heading}>Problem</h5>
                    <div className={styles.text}>
                        {data[name].problem.map((text, index) => <p key={index}>{text}</p>)}
                    </div>
                </div>}
            {data[name].problemImg.length > 0
                && <div className="d-flex gap-3 md:gap-5 flex-column flex-md-row">
                    {data[name].problemImg.map((img, index) => {
                        return <div className="w-100 d-flex" key={index}>
                            <img className="w-100 h-auto rounded-5" src={img} alt={name} />
                        </div>
                    })}
                </div>}
            {data[name].solutions.length > 0
                && <div className={styles.infoBox}>
                    <h5 className={styles.heading}>Solutions</h5>
                    <div className={styles.text}>
                        {data[name].solutions.map((text, index) => <p key={index}>{text}</p>)}
                    </div>
                </div>}

            {data[name].logoDesign.length > 0
                && <div className={styles.infoBox}>
                    <h5 className={styles.heading}>Logo Design</h5>
                    <div className={styles.text}>
                        {data[name].logoDesign.map((text, index) => <p key={index}>{text}</p>)}
                    </div>
                </div>}

            {name === "basha-vapes"
                && <>
                    <div className="rounded-5">
                        <img className="w-100 h-auto" src={bashavapesLogo} alt="bashaVapes-logo" />
                    </div>
                    <div className={styles.points}>
                        <p>
                            <span>Primary : </span>
                            Matte black - Conveying elegance, power, and sophistication.
                        </p>
                        <p>
                            <span>Secondary : </span>
                            White accents highlight intricate details to the Lion and give the logo a touch of royalty and luxury.
                        </p>
                        <p>The lion's head with a crown is centrally positioned, acting as the main focal point within the shield and a majestic, detailed representation of a lion's face, capturing its powerful gaze and regal mane. The lion's eyes are intricately outlined in red against the matte black background, emphasising its features and making it stand out.</p>
                    </div>
                    <div className={styles.infoBox}>
                        <h5 className={styles.heading}>Web Design</h5>
                        <div className={styles.text}>
                            {["A fusion of modern aesthetics with a dash of funky charm, creating a unique interface that appeals to both contemporary and classic vaping enthusiasts. The design reflects the sophistication of vaping culture, ensuring that visitors feel both informed and inspired.",
                                "The idea was to showcase all the vaping products to the buyers in a way that is simple but not usual. We gave it colours that are bright and bold. Primarily, we used Deep Orange to convey trust and sophistication and Secondarily,  the Golden yellow gave a nod to the golden hues of many vaping liquids and to add a depth of warmth to the entire website.",
                                "We created a simple user friendly interface for the buyers to buy products quickly and with ease. The aesthetics of the website needed to be such that the products were shown boldly and closely. Thus, we used bigger pictures and the primary colours of the website helped us bring out the products to be better visually.",
                                "Basha Vapes website will serve as a comprehensive platform for both seasoned vapers and newcomers. It will be a blend of stylish design, user-friendly interfaces, and rich content, ensuring that visitors not only find what they are looking for but also enjoy their browsing experience."
                            ].map((text, index) => <p key={index}>{text}</p>)}
                        </div>
                    </div>
                    <div className={styles.infoBox}>
                        <h5 className={styles.heading}>Graphic Design</h5>
                        <div className={styles.text}>
                            {["A playful yet sophisticated fusion of vibrant hues and modern design elements, reflecting the vivacious energy and quality of Basha Vapes products. This design ensures the brand stands out in a competitive market, appealing especially to a younger, trend-conscious audience while retaining an air of professionalism. We have reflected this idea on the graphic designs that are used for Social Media and Email Marketing Channels.",
                                "As Basha Vapes is a newly launched brand, the most important aspect of it is to create a genuine and organic local awareness. To do this, the aim is to make attractive designs that show the vapes in a bold and eye-catching way. For example, A gradient effect that smoothly transitions from Electric effect and to Neon style designs which provides a radiant backdrop.",
                            ].map((text, index) => <p key={index}>{text}</p>)}
                        </div>
                        <p className={styles.marketType}>The 3 types of design ideas have been concluded for the Marketing plan.</p>
                        <ol className={styles.marketTypeList}>
                            <li>Informative</li>
                            <li>Minimalistic</li>
                            <li>Interactive </li>
                        </ol>
                        <p className={styles.logoDesignFoot}>
                            Overall, Basha Vapes' graphic design embodies the spirit of modern-day fun, vibrancy, and innovation. The popping colours and dynamic shapes grab attention, while the strategic placement of products and information ensures the brand's message is clear. This design isn't just a visual treat
                            <b>—it's an energetic declaration of what Basha Vapes represents in the vaping industry.</b>
                        </p>
                    </div>
                </>}

            {data[name].logoImg.length > 0
                && <div className={`${styles.imagesContainer} ${data[name].logoImg.length === 1 && "gap-0"} mb-5`}>
                    {data[name].logoImg.map((img, index) => {
                        return <div className="w-100 d-flex" key={index}>
                            <img className="w-100 h-auto rounded-5" src={img} alt={name} />
                        </div>
                    })}
                </div>}

            {data[name].images.length > 0
                && <div className={styles.imagesContainer}>
                    {data[name].images.map((img, index) => {
                        return <div className="w-100 d-flex" key={index}>
                            <img className="w-100 h-auto rounded-5" src={img} alt={name} />
                        </div>
                    })}
                </div>}

            {data[name].extraText.length > 0
                && <div className={styles.infoBox}>
                    <div className={styles.text}>
                        {data[name].extraText.map((text, index) => <p key={index}>{text}</p>)}
                    </div>
                </div>}
            {data[name].card.length > 0
                && <div className="d-flex gap-3 gap-lg-5 flex-column flex-md-row">
                    {data[name].card.map((img, index) => {
                        return <div className="w-100 d-flex" key={index}>
                            <img className="w-100 h-auto rounded-5" src={img} alt={name + "GiftCards"} />
                        </div>
                    })}
                </div>}
            {data[name].points.length > 0
                && < div className={styles.infoBox}>
                    <h5 className={styles.heading}>Results (GET NUMBERS)</h5>
                    <div className={styles.imagesContainer}>
                        {data[name].points.map((text, index) => {
                            return <div className={`${styles.pointsMain} w-100 d-flex gap-2`} key={index}>
                                <div>
                                    {tickIcon}
                                </div>
                                <p className={styles.pointsText}>{text}</p>
                            </div>
                        })}
                    </div>
                </div>}
        </div >
    )
}

export default ClientDesignDetails