import { useRef } from 'react'
import styles from '../../assets/css/blogDetails.module.scss'
import Play from '../../Components/PlayButton/Play'

const img1 = 'https://mysocialtheory.com/assets/images/blog/blog3-1.png'
const img2 = 'https://mysocialtheory.com/assets/images/blog/blog3-2.png'
const img3 = 'https://mysocialtheory.com/assets/images/blog/blog3-3.png'
const video1 = 'https://mysocialtheory.com/assets/videos/blog3-1.mp4'


const Blog3 = () => {
    const constraintsRef = useRef(null);
    const videoRef = useRef(null)

    return (
        <div className={styles.main}>
            <h3 className={styles.heading}>The Strategic Edge: 12 Reasons to Invest in a Social Media Marketer's Expertise</h3>
            <div className={styles.contents}>
                <i>"The successful warrior is the average man, with laser-like focus." <span>- Bruce Lee</span></i>
                <p>In the modern battlefield of business, where attention is the most coveted currency, a social media marketer emerges as the strategic commander. Just as a skilled warrior directs their focus toward victory, a proficient social media marketer channels their expertise to elevate your brand's presence and impact. Let's delve into the pivotal reasons why collaborating with a social media marketer is an investment that can reshape your brand's trajectory.</p>
                <div className={styles.imgContainer}>
                    <img src={img1} alt="digital" />
                </div>
            </div>
            <div className={styles.contents}>
                <ol className={styles.points}>
                    <li>
                        <p><span>Strategic Precision : </span>Like a maestro orchestrating a symphony, a social media marketer orchestrates your brand's online narrative. They craft a strategic plan tailored to your goals, aligning each post and campaign with a purpose that resonates across digital landscapes.</p>
                    </li>
                    <li>
                        <p><span>Creativity with Purpose : </span>Creativity isn't just about aesthetics; it's about delivering a resonating message. A social media marketer fuses creativity with a deep understanding of your audience, crafting content that doesn't just capture eyes but captures hearts.</p>
                    </li>
                    <li>
                        <p><span>Target Audience Insight : </span>Understanding your audience is at the heart of impactful marketing. A social media marketer delves into data, surveys trends, and analyzes demographics to tailor your content for maximum engagement and connection.</p>
                    </li>
                    <div className={styles.imgContainer}>
                        <img src={img2} alt="audience" />
                    </div>
                    <li>
                        <p><span>Data-Informed Evolution : </span>In the digital realm, data is gold. A social media marketer mines this gold, deciphering metrics, gauging post performance, and deriving insights that inform and evolve strategies for consistent growth.</p>
                    </li>
                    <li>
                        <p><span>Consistency and Branding : </span>Inconsistency weakens even the mightiest of brands. A social media marketer safeguards your brand identity across platforms, maintaining a unified voice and aesthetic that builds brand recognition and trust.</p>
                    </li>
                    <li>
                        <p><span>Crisis Mitigation : </span>Every brand faces storms. A social media marketer functions as your brand's crisis handler, adept at managing difficult situations and turning them into opportunities for growth.</p>
                    </li>
                    <li>
                        <p><span>Targeted Expansion : </span>Social media's global reach is unparalleled. A social media marketer utilizes targeting tools to expand your audience, engaging with potential customers who might have remained invisible without their expertise.</p>
                    </li>
                    <li>
                        <p><span>Time Efficiency and Expertise : </span>Just as Bruce Lee's laser-like focus maximized his efficiency, a social media marketer's expertise streamlines your efforts. They've mastered the landscape, saving you from trial and error while freeing you to focus on your core business.</p>
                    </li>
                    <li>
                        <p><span>Effective Hashtag Utilization : </span>Hashtags are essential for increasing the discoverability of your content. However, using the right hashtags requires research and strategy. Social media marketers analyze trending hashtags and industry-specific keywords to ensure your posts reach a broader audience.</p>
                    </li>
                    <li>
                        <p><span>Localised and Global Reach : </span>Depending on your target audience, you might need to focus on either local or global reach. Social media marketers understand how to tailor your content and targeting to cater to specific geographical regions, languages, and cultural nuances.</p>
                    </li>
                    <div className={styles.imgContainer}>
                        <img src={img3} alt="search" />
                    </div>
                    <li>
                        <p><span>Paid Advertising Expertise : </span>Social media platforms offer powerful advertising tools that can help you reach a highly targeted audience. Social media marketers are skilled in creating and optimising paid ad campaigns. They can design compelling ad creatives, select the right audience demographics, and manage your ad budget effectively to maximise your return on investment.</p>
                    </li>
                    <div className='w-100 position-relative' ref={constraintsRef}>
                        <video ref={videoRef} loop mute className="w-100 h-auto rounded-5" src={video1}></video>
                        <Play constraintsRef={constraintsRef} videoRef={videoRef} />
                    </div>
                    <li>
                        <p><span>Adapting to Trends : </span>Social media trends and best practices are constantly evolving. What worked last year might not be as effective today. Social media marketers stay updated with the latest trends and can adapt your strategy accordingly, ensuring your brand remains relevant and innovative.</p>
                    </li>
                </ol>
                <p>In the age of attention scarcity, a social media marketer's focus is the beacon guiding your brand through the digital battlefield. Their skillful blend of strategy, creativity, and data-driven insights propels your brand's narrative to new heights. So, envision your brand as the warrior, and a social media marketer as your strategist, mapping the path to digital conquest with a masterful stroke.</p>
            </div>
        </div>
    )
}

export default Blog3