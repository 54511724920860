import styles from "./Project.module.scss";
import Fade from 'react-reveal'
import { Link } from "react-router-dom";

const Branding = 'https://mysocialtheory.com/assets/videos/Branding.mp4'

const Tab3 = () => {
  return (
    <div className={`${styles.tab1_main} d-flex flex-column gap-4`}>
      <Fade right>
        <Link to="/concept-designs" className="d-flex">
          <video autoPlay muted loop className="w-100 h-auto" src={Branding} />
        </Link>
      </Fade>
    </div>
  );
};

export default Tab3;
