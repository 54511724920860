import CompanySlider from "../CompanySlider/CompanySlider";
import Professional from "../Professional/Professional";
import CountUp from "react-countup";
import styles from "./Journey.module.scss";
import { TypeAnimation } from "react-type-animation";
import { Bounce, Fade } from 'react-reveal';

const aboutus = "https://mysocialtheory.com/assets/images/ourstory.png";

const Journey = () => {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.heading} >
          <p className="mb-0">Social Theory - Story</p>
        </div>
        <div className={styles.content}>
          <Bounce left>
            <div>
              <h6 className="mb-2 mb-md-4">
                Keeping it real<span></span>
              </h6>
              <p className="mb-0">
                Let's just say we don’t go around the bush. We are true to our
                work and people that work with us. If there’s one thing you need
                to know about us is we believe in values of being simple, straight
                forward and down to the goddamn earth!
              </p>
            </div>
          </Bounce>
          <Bounce right>
            <div>
              <h6 className="mb-2 mb-md-4">
                Humanize <span></span>
              </h6>
              <p className="mb-0">
                Our priority is the people in our business and work. A new genuine
                experience is what really keeps us going. Creativity is at its
                heart. If you’re happy, we are happy.
              </p>
            </div>
          </Bounce>
          <Bounce left>
            <div>
              <h6 className="mb-2 mb-md-4">
                Bright and New<span></span>
              </h6>
              <p className="mb-0">
                Someone once said “It can always be better and brighter”. This is
                our mantra on a daily basis. We make sure our work is insightful
                and logical. We thrive to make a real difference here.
              </p>
            </div>
          </Bounce>
          <Bounce right>
            <div >
              <h6 className="mb-2 mb-md-4">
                Focus on Long Term Priorities<span></span>
              </h6>
              <p className="mb-0">
                Look, relationships are highly valuable in every industry. What we
                do now, comes to fruit in time. We think it is important to build
                on something and stand together to make a brand better and bigger.
                Our priority is to stay long term with our clients so we can grow
                with them.
              </p>
            </div>
          </Bounce>
        </div>
      </div>
      <div className={styles.story_main + " overflow-hidden mt-5"}>
        <div className="row mx-0 p-4 pe-md-0 py-md-0 ps-md-4 ps-xl-5">
          <Fade left>
            <div className="px-0 aboutus_right_para d-none d-md-flex col-md-6 align-items-end justify-content-start">
              <p className="mb-0">
                Hey, remember to be awesome today! We are thrilled to have you here.Take a moment to get to know us and what we are all about.
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className="position-relative col-md-6 px-0 text-center text-md-end">
              <TypeAnimation
                className="aboutus_heading ourstory_heading"
                sequence={["Our Story"]}
                wrapper="p"
                speed={0.5}
              />
              <div className="aboutus_right_para d-block d-md-none">
                <p>
                  Hey, remember to be awesome today! We are thrilled to have you here.Take a moment to get to know us and what we are all about.
                </p>
              </div>
              <img
                className="aboutus_img w-100 h-auto rounded-4"
                src={aboutus}
                alt="about-us"
              />
            </div>
          </Fade>
        </div>
        <div className={styles.story + " p-4 px-md-4 px-xl-5 mt-0 mt-md-4"}>
          <p className="mb-2">At Social Theory, our journey has been a wild ride filled with passion for digital awesomeness and a whole lot of dedication to empowering businesses like yours. We started off with a crazy dream to shake things up in the digital marketing world and help our clients rock their way to success in this fast-paced online playground.</p>
          <p className="mb-2">The two founders of Social Theory met on a random day in college and Football is what got them close. 11 Years later they started a company about something they were equally passionate about. We have had our fair share of experience in this industry before we decided that it was time to leave things behind and do this together. Life was kind to us because things somehow did come together. We enjoy what we do and bring results because we have a blast working together.
            When you start something, you can’t predict how in reality things will go about. We thought it might be just us out there working. Who could’ve thought we’d have an amazing team working along with us and making things happen? Dreams only come to reality when it's the risks that give them a hard push.
            We've grown from a tiny operation to a kickass digital marketing agency that works with all sorts of cool clients from different industries. Along the way, we've helped a lot of companies and especially start ups to get to that very dream of making it and making it well. We understand how difficult it gets because we were and are still there.</p>
          <p className="mb-2">You know what sets us apart?</p>
          <p className="mb-2">We've helped a lot of companies and especially start ups to get to that very dream of making it and making it well. We understand how difficult it gets because we were and are still there. We also understand a lot of new companies do not have a cash liberty, that is why we say, let’s talk price and come to something that is best for everyone!</p>
          <p className="mb-2">All the credits go to our crew of digital marketing wizards who eat, breathe, and live this stuff. Seriously, they're like superheroes in disguise. They bring together their mad skills, expert knowledge, and crazy innovative strategies to give our clients nothing short of mind-blowing results. And the best part? We're always on top of the latest trends and tech because we want to serve up the freshest, most kickass solutions in town.</p>
          <p className="mb-2">Man, let me tell you, we've seen some seriously mind-blowing transformations happen thanks to the power of digital marketing. We've helped businesses establish their online presence, get noticed like never before, drive loads of targeted traffic, and even achieve some mind-boggling growth
            But hey, our journey is far from over. We're constantly pushing boundaries, trying new things, and aiming for greatness in every damn campaign we tackle. We're all about giving you nothing but the best because your success is our ultimate goal. So, strap in and join us on this wild ride. Together, we'll unlock new opportunities, crush those challenges, and create a digital success story that'll leave everyone in awe.
            Ready to rock? Let's do this!</p>
        </div>
      </div>
      <div
        className={`${styles.status} d-flex flex-column flex-sm-row justify-content-center justify-content-xl-between align-items-center`}
      >
        <div className="text-center">
          <h5 className="mb-2">
            <CountUp duration={2} enableScrollSpy start={0} end={19.6} decimals={1} suffix="M" />
          </h5>
          <p className="mb-0">$Earned for our clients</p>
        </div>
        <div className="text-center">
          <h5 className="mb-2">
            <CountUp duration={2} enableScrollSpy start={0} end={570} suffix="%" />
          </h5>
          <p className="mb-0">return on investments</p>
        </div>
        <div className="text-center">
          <h5 className="mb-2">
            <CountUp duration={2} enableScrollSpy start={0} end={15} suffix="M+" />
          </h5>
          <p className="mb-0">Impressions</p>
        </div>
        <div className="text-center">
          <h5 className="mb-2">
            <CountUp duration={2} enableScrollSpy start={0} end={3.5} decimals={1} suffix="M+" />
          </h5>
          <p className="mb-0">Reaches</p>
        </div>
        <div className="text-center">
          <h5 className="mb-2">
            <CountUp duration={2} enableScrollSpy start={0} end={25} suffix="+" />
          </h5>
          <p className="mb-0">clients worked with</p>
        </div>
      </div>
      <Professional />
      <div className={styles.company_slider}>
        <CompanySlider />
      </div>
    </>
  );
};

export default Journey;
