import { gsap } from "gsap";
import Background from "../Components/Common/Background";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import Translate3d from "../Constants/Translate3d";
import { useNavigate } from "react-router-dom";
import styles from "../assets/css/approach.module.scss";

const border = 'https://mysocialtheory.com/assets/images/border-blue.svg'

gsap.registerPlugin(ScrollTrigger)

const Approach = () => {

  const mainRef = useRef(null)
  const bgImgRef = useRef(null)
  const navigate = useNavigate()

  useLayoutEffect(() => {
    let sections = gsap.utils.toArray(mainRef.current.children[0].children);
    let headerHeight = document.getElementsByTagName("nav")[0].offsetHeight + "px"
    const mm = gsap.matchMedia();

    let ctx = gsap.context(() => {
      mm.add(
        '(min-width: 992px)',
        () => {
          gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none",
            scrollTrigger: {
              trigger: mainRef.current.children,
              pin: mainRef.current,
              pinSpacing: true,
              scrub: 1,
              start: `top ${headerHeight}`,
              end: "+=6000",
            }
          });

          gsap.to(bgImgRef.current, {
            backgroundPosition: "69% 50%",
            ease: "none",
            scrollTrigger: {
              trigger: mainRef.current.children,
              start: `top ${headerHeight}`,
              end: "bottom top",
              scrub: 2,
            }
          });
        },
        mainRef
      )
    })

    return () => ctx.revert();
  }, [])

  useLayoutEffect(() => {
    Translate3d(document.querySelectorAll("#translate3d"))
  }, [])

  return (
    <Background backgroundColor="#0d0d0d">
      <div ref={mainRef} className={styles.main}>
        <div className={styles.horizontal_sliders}>
          <div className={styles.panel}>
            <div className={styles.panel1}>
              <div className={styles.description}>
                <span>NOT SELLING DREAMS:</span>
                <h4>
                  TRANSFORM YOUR VISIONS TO REALITIES
                </h4>
                <p>We make sure what we do and how we do follows a way of analysing everything properly that gives the right results. We are transparent and objective driven. Our idea is to place our clients with a team that suits them the best and exceeds all expectations.</p>
              </div>
              <div className={styles.right}>
                <div className="position-relative">
                  <div className={styles.number}>
                    <img src={border} alt="border" />
                    <span>1</span>
                  </div>
                  <div className={styles.texts}>
                    <h6 className={styles.heading1}>In Depth Analysis</h6>
                    <p>Before we start any project we want to understand the story of it all. Deep questioning is required to get a broader perspective of the need. This taps into the emotions and pain points of the brand and the overall outcome is improved. </p>
                  </div>
                  {/* <div ref={bgImgRef} className={styles.bg_img + " " + styles.bg_img1}></div> */}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.panel2}>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>2</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading2}>Honesty & Complete Transparency</h6>
                  <p>It is our forte to remain in constant touch with anyone we work with total transparency. We realise the expectation one sets for us and remain honest about all the timelines, pricing and deliverables. For such commitment we are always available around the clock!</p>
                </div>
              </div>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>3</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading3}>Objective oriented</h6>
                  <p>
                    From the beginning of a project, we set ourselves milestones on the way to achieve the best possible end results. This is what we enjoy and live for.
                  </p>
                </div>
                {/* <div ref={bgImgRef} className={styles.bg_img + " " + styles.bg_img2}></div> */}
              </div>
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.panel3}>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>4</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading4}>Best working team</h6>
                  <p>We make sure our clients get the best possible team to work with on their projects. We have a diverse base of people that work diligently and efficiently. The clients are put in direct touch with whenever they need. Our intention is to save our clients their time and money.</p>
                </div>
              </div>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>5</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading5}>Beyond expectations</h6>
                  <p>We are known to go above and beyond what is expected of us. We want what's best and give a long term solution for everyone we work with. This is who we are.
                  </p>
                </div>
                {/* <div ref={bgImgRef} className={styles.bg_img + " " + styles.bg_img3}></div> */}
              </div>
            </div>
          </div>
          <div className={styles.panel}>
            <div className={styles.panel4}>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>6</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading6}>Focus on better </h6>
                  <p>Our main focus is on the idea of “room for improvement”. We give every design the edge of modern elements and beauty and we believe everything we do should leave a lasting impact.</p>
                </div>
              </div>
              <div id="translate3d" className={styles.main}>
                <div className={styles.number}>
                  <img src={border} alt="border" />
                  <span>7</span>
                </div>
                <div className={styles.texts}>
                  <h6 className={styles.heading7}>Adaptations</h6>
                  <p>We realise how important it is to meet the modern trends or at times be old school. We are not social theory if we aren’t adaptable. We have a diverse set of skills to make sure our clients get what they need.</p>
                </div>
                {/* <div ref={bgImgRef} className={styles.bg_img + " " + styles.bg_img4}></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.gobackdesk} onClick={() => navigate(-1)}>
          <div className={styles.texts}>
            <span>Go back</span>
            <div className={styles.arrowback}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.894" height="9.597" viewBox="0 0 18.894 9.597">
                <g transform="translate(-69.053 -1037.201)">
                  <g transform="translate(-150.201 764.698)">
                    <path d="M219.547,277.594h17.6l-4.031,4.011a.29.29,0,0,0,0,.412.3.3,0,0,0,.415,0l4.53-4.508.007-.008a.166.166,0,0,0,.016-.02.1.1,0,0,0,.011-.015l.013-.02a.146.146,0,0,0,.009-.018.091.091,0,0,0,.006-.013l0-.008a.112.112,0,0,0,.007-.019c0-.007,0-.014.006-.022a.194.194,0,0,0,0-.02.091.091,0,0,0,0-.022.119.119,0,0,0,0-.021.13.13,0,0,0,0-.021.093.093,0,0,0,0-.022c0-.007,0-.013,0-.02s0-.014-.006-.022a.18.18,0,0,0-.006-.018.04.04,0,0,1,0-.008.1.1,0,0,0-.007-.014.08.08,0,0,0-.009-.017.2.2,0,0,0-.013-.021.094.094,0,0,0-.011-.015.119.119,0,0,0-.016-.019l-.008-.009-4.53-4.507a.295.295,0,0,0-.415,0,.29.29,0,0,0,0,.411h0l4.031,4.01h-17.6a.291.291,0,1,0,0,.582Z" fill="#333"></path>
                  </g>
                  <path d="M82.916,1046.714a.289.289,0,0,1,0-.411l4.031-4.011h-17.6a.291.291,0,1,1,0-.581h17.6l-4.031-4.01h0a.29.29,0,0,1,0-.411.295.295,0,0,1,.415,0l4.53,4.507.008.009a.089.089,0,0,1,.016.02l.011.014a.1.1,0,0,1,.013.021.061.061,0,0,1,.009.017.049.049,0,0,1,.007.014l0,.008a.107.107,0,0,1,.006.018.162.162,0,0,0,.006.023l0,.02a.094.094,0,0,1,0,.022.091.091,0,0,1,0,.022.08.08,0,0,1,0,.021.1.1,0,0,1,0,.023l0,.019c0,.008,0,.015-.006.022a.163.163,0,0,1-.006.019l0,.007a.046.046,0,0,1-.006.013.113.113,0,0,1-.01.018c0,.007-.008.013-.013.021l-.011.015c0,.006-.011.013-.016.019l-.008.008-4.53,4.508a.3.3,0,0,1-.415,0Z" fill="#a2429e"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.gobackmob} onClick={() => navigate(-1)}>
          <div className={styles.texts}>
            <span>Go back</span>
            <div className={styles.arrowback}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.894" height="9.597" viewBox="0 0 18.894 9.597">
                <g transform="translate(-69.053 -1037.201)">
                  <g transform="translate(-150.201 764.698)">
                    <path d="M219.547,277.594h17.6l-4.031,4.011a.29.29,0,0,0,0,.412.3.3,0,0,0,.415,0l4.53-4.508.007-.008a.166.166,0,0,0,.016-.02.1.1,0,0,0,.011-.015l.013-.02a.146.146,0,0,0,.009-.018.091.091,0,0,0,.006-.013l0-.008a.112.112,0,0,0,.007-.019c0-.007,0-.014.006-.022a.194.194,0,0,0,0-.02.091.091,0,0,0,0-.022.119.119,0,0,0,0-.021.13.13,0,0,0,0-.021.093.093,0,0,0,0-.022c0-.007,0-.013,0-.02s0-.014-.006-.022a.18.18,0,0,0-.006-.018.04.04,0,0,1,0-.008.1.1,0,0,0-.007-.014.08.08,0,0,0-.009-.017.2.2,0,0,0-.013-.021.094.094,0,0,0-.011-.015.119.119,0,0,0-.016-.019l-.008-.009-4.53-4.507a.295.295,0,0,0-.415,0,.29.29,0,0,0,0,.411h0l4.031,4.01h-17.6a.291.291,0,1,0,0,.582Z" fill="#333"></path>
                  </g>
                  <path d="M82.916,1046.714a.289.289,0,0,1,0-.411l4.031-4.011h-17.6a.291.291,0,1,1,0-.581h17.6l-4.031-4.01h0a.29.29,0,0,1,0-.411.295.295,0,0,1,.415,0l4.53,4.507.008.009a.089.089,0,0,1,.016.02l.011.014a.1.1,0,0,1,.013.021.061.061,0,0,1,.009.017.049.049,0,0,1,.007.014l0,.008a.107.107,0,0,1,.006.018.162.162,0,0,0,.006.023l0,.02a.094.094,0,0,1,0,.022.091.091,0,0,1,0,.022.08.08,0,0,1,0,.021.1.1,0,0,1,0,.023l0,.019c0,.008,0,.015-.006.022a.163.163,0,0,1-.006.019l0,.007a.046.046,0,0,1-.006.013.113.113,0,0,1-.01.018c0,.007-.008.013-.013.021l-.011.015c0,.006-.011.013-.016.019l-.008.008-4.53,4.508a.3.3,0,0,1-.415,0Z" fill="#a2429e"></path>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Background>
  );
};

export default Approach;
