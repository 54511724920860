import styles from "./Common.module.scss";
import { Link } from "react-router-dom";

const arrow = "https://mysocialtheory.com/assets/images/double_gradient_arrow.svg";
const portfolioCard3 = "https://mysocialtheory.com/assets/images/portfolioCard3.png";
const portfolioCard4 = "https://mysocialtheory.com/assets/images/portfolioCard4.png";
const portfolioCard5 = "https://mysocialtheory.com/assets/images/portfolioCard5.png";
const gallery1 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol.png'
const gallery2 = 'https://mysocialtheory.com/assets/images/concept/graphic/burger.png'
const gallery3 = 'https://mysocialtheory.com/assets/images/concept/logo/grocery.png'
const gallery4 = 'https://mysocialtheory.com/assets/images/concept/ui-ux/pizza.png'
const gallery5 = 'https://mysocialtheory.com/assets/images/concept/webDesign/headphones.png'
const gallery6 = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging.png'

const Sidebar = () => {
  return (
    <div className={styles.sidebar_main}>
      <div className={styles.box}>
        <div className={styles.heading}>
          <img src={arrow} alt="arrow" />
          <h6>Search</h6>
        </div>
        <div className={styles.search}>
          <input type="text" placeholder="Search your keyword" />
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.heading}>
          <img src={arrow} alt="arrow" />
          <h6>Related Posts</h6>
        </div>
        <div className={styles.post}>
          <div className={styles.image}>
            <img src={portfolioCard5} alt="project-image" />
          </div>
          <div className={styles.headings}>
            <span>July 25,2023</span>
            <p>Accelerating UX Maturity With A Breakthrough Project</p>
          </div>
        </div>
        <div className={styles.post}>
          <div className={styles.image}>
            <img src={portfolioCard4} alt="project-image" />
          </div>
          <div className={styles.headings}>
            <span>July 25,2023</span>
            <p>Build your Website Design Better World Today</p>
          </div>
        </div>
        <div className={styles.post}>
          <div className={styles.image}>
            <img src={portfolioCard3} alt="project-image" />
          </div>
          <div className={styles.headings}>
            <span>July 25,2023</span>
            <p>How to Become a Graphic Designer in 10 Steps</p>
          </div>
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.heading}>
          <img src={arrow} alt="arrow" />
          <h6>Tags</h6>
        </div>
        <div className="d-flex flex-wrap gap-2   gap-sm-3">
          {[
            "design",
            "digital",
            "Content",
            "innovation",
            "marketing",
            "mobile",
            "tech",
            "technology",
            "web",
            "wordpress",
          ].map((tag, index) => (
            <div key={index} className={styles.tag}>
              {tag}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.box}>
        <div className={styles.heading}>
          <img src={arrow} alt="arrow" />
          <h6>Gallery</h6>
        </div>
        <div className="d-flex flex-wrap gap-3 justify-content-center">
          {[gallery1, gallery2, gallery3, gallery4, gallery5, gallery6].map((img, index) => (
            <Link to="/concept-designs" key={index} className={styles.gallery_img}>
              <img src={img} alt="project-image" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
