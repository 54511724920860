import styles from "./Project.module.scss";
import Fade from 'react-reveal'
import { Link } from "react-router-dom";

const bashaVapes = 'https://mysocialtheory.com/assets/videos/Basha_Vapes.mp4'

const Tab2 = () => {
  return (
    <div className={`${styles.tab1_main} d-flex flex-column gap-4`}>
      <Fade right>
        <Link to="/client-designs/basha-vapes" className="d-flex">
          <video autoPlay muted loop className="w-100 h-auto" src={bashaVapes}></video>
        </Link>
      </Fade>
    </div >
  );
};

export default Tab2;
