import { useForm } from "react-hook-form";
import { contactUSSchema } from '../../lib/schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from "react-toastify";

const ContactForm = ({ heading, text }) => {

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(contactUSSchema),
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    }
  })

  const onSubmit = async (values) => {
    try {
      const formData = new FormData()

      Object.entries(values).forEach(([key, values]) => formData.append(key, values))

      const res = await fetch("https://client.appmania.co.in/socialtheory/mail-contact-us.php", {
        method: "POST",
        body: formData
      })

      const data = await res.json()

      if (data.ResponseCode === 1) {
        toast.success(data.ResponseMsg)
        reset()
      } else {
        toast.error(data.ResponseMsg)
      }
    } catch (error) {
      toast.error("Something went wrong")
      console.log(error)
    }
  }

  return (
    <section className="contact_form">
      <div className="container-fluid">
        <div className="form_heading">
          <h2>{heading}</h2>
          <p>{text}</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="p-0 pe-lg-2 col-12 col-lg-6">
              <div className="form_control">
                <label htmlFor="name">Name*</label>
                <input id="name" type="text" placeholder="Name" {...register('name', { required: true })} />
              </div>
            </div>
            <div className="p-0 ps-lg-2 col-12 col-lg-6">
              <div className="form_control">
                <label htmlFor="email">Email*</label>
                <input id="email" type="email" placeholder="Email" {...register('email', { required: true })} />
              </div>
            </div>
            <div className="px-0 col-12">
              <div className="form_control">
                <label htmlFor="website">Website*</label>
                <input id="website" type="text" placeholder="Website" {...register('subject', { required: true })} />
              </div>
            </div>
            <div className="px-0 col-12">
              <div className="form_control">
                <label htmlFor="message">Message*</label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="4"
                  placeholder="Simultaneously we had a problem"
                  {...register('message', { required: true })}
                ></textarea>
              </div>
            </div>
            <div className="submit_btn text-center">
              <button type="submit">submit now</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
