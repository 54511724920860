import Background from "../Components/Common/Background";
import styles from "../assets/css/blog.module.scss";
import style from "../assets/css/blogDetails.module.scss";
import { Link, useParams } from "react-router-dom";
import Comment from "../Components/Common/Comment";
import ContactForm from "../Components/Common/ContactForm";
import { Fade, Bounce } from 'react-reveal'
import data from '../Constants/BlogData'
import Blog1 from "../Components/BlogDetail/Blog1";
import Blog2 from "../Components/BlogDetail/Blog2";
import Blog3 from "../Components/BlogDetail/Blog3";

const fileIcon = "https://mysocialtheory.com/assets/images/file-icon.svg";
const personIcon = "https://mysocialtheory.com/assets/images/person-icon.svg";
const calendarIcon = "https://mysocialtheory.com/assets/images/calendar-icon.svg";
const chatIcon = "https://mysocialtheory.com/assets/images/chat-icon.svg";
const arrowRight = "https://mysocialtheory.com/assets/images/gradiant_arrow_right.svg";
const userImg = "https://mysocialtheory.com/assets/images/userimage.png";
const blog1 = 'https://mysocialtheory.com/assets/images/blog/blog1.png'
const blog2 = 'https://mysocialtheory.com/assets/images/blog/blog2.png'
const blog3 = 'https://mysocialtheory.com/assets/images/blog/blog3.png'


const BlogDetails = () => {
  const { name } = useParams()

  return (
    <Background backgroundColor="#0d0d0d">
      <div
        className={`${style.main} position-relative d-flex flex-column flex-lg-row overflow-x-hidden px-2 px-md-4`}
      >
        <Fade left>
          <div className={`${styles.right_main}`}>
            <div>
              <div className={styles.blog_img}>
                <img
                  className="w-100 h-auto"
                  src={data[name].bannerImg}
                  alt="blog-image"
                />
              </div>
              <div className={styles.info}>
                <div className="d-flex gap-2 gap-xl-3 align-items-center">
                  <img src={fileIcon} alt="file-icon" />
                  <span>{data[name].type}</span>
                </div>
                <div className="d-flex gap-2 gap-xl-3 align-items-center">
                  <img src={personIcon} alt="file-icon" />
                  <span>{data[name].author}</span>
                </div>
                <div className="d-flex gap-2 gap-xl-3 align-items-center">
                  <img src={calendarIcon} alt="file-icon" />
                  <span>{data[name].date}</span>
                </div>
                <div className="d-flex gap-2 gap-xl-3 align-items-center">
                  <img src={chatIcon} alt="file-icon" />
                  <span>{data[name].totalComments}</span>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      {name === "marketing: an ever evolving yet a timeless skill" && <Blog1 />}

      {name === "why trendy graphic designs are a game-changer for your business & social media" && <Blog2 />}

      {name === "the strategic edge: 12 reasons to invest in a social media marketer's expertise" && <Blog3 />}

      <div className={`${style.bottomMain} overflow-x-hidden`}>
        <section className="latest_posts px-2 px-md-4">
          <div>
            <div className="latestpost_heading d-flex flex-column flex-md-row justify-content-between align-items-center">
              <h2 className="text-center mb-0">Related Projects</h2>
            </div>
            <Bounce left cascade>
              <div className="row g-4 justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="latest_posts_box">
                    <img src={blog1} alt="blog1" />
                    <h4>Marketing: An ever evolving yet a timeless skill</h4>
                    <Link to={"/blog-detail/marketing: an ever evolving yet a timeless skill"}>
                      Read More
                      <img src={arrowRight} alt="arrow-right" />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="latest_posts_box">
                    <img className="w-100 h-auto" src={blog2} alt="blog2" />
                    <h4>Why Trendy Graphic Designs are a Game-Changer for Your Business & Social Media</h4>
                    <Link to={"/blog-detail/why trendy graphic designs are a game-changer for your business & social media"}>
                      Read More
                      <img
                        className="w-100 h-auto"
                        src={arrowRight}
                        alt="arrow-right"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="latest_posts_box">
                    <img src={blog3} alt="blog3" />
                    <h4>The Strategic Edge: 12 Reasons to Invest in a Social Media Marketer's Expertise</h4>
                    <Link to={"/blog-detail/the strategic edge: 12 reasons to invest in a social media marketer's expertise"}>
                      Read More
                      <img
                        className="w-100 h-auto"
                        src={arrowRight}
                        alt="arrow-right"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </Bounce>
          </div>
        </section>
        {/* <div className={`${style.comments}`}>
          <h4>Comments (02)</h4>
          <div className="d-flex flex-column">
            <Comment
              image={userImg}
              name="Benjamin"
              date="July 28, 2022"
              text="Many UX designers, developers, and entrepreneurs take pride in how hard it was to solve hard
             challenges. I don’t. I hope to convince you not to either. I’m known for enjoying the process of solving
             impossible problems, I learned it first-hand from a master."
            />
            <Comment
              isSubComment
              image={userImg}
              name="Benjamin"
              date="July 28, 2022"
              text="Many UX designers, developers, and entrepreneurs take pride in how hard it was to solve hard
             challenges. I don’t. I hope to convince you not to either. I’m known for enjoying the process of solving
             impossible problems, I learned it first-hand from a master."
            />
          </div>
        </div> */}
        <ContactForm
          heading="Leave a Reply"
          text="Your email address will not be published. Required fields are marked *"
        />
      </div>

    </Background>
  );
};

export default BlogDetails;
