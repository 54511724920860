import React from "react";
import Background from "../Components/Common/Background";
import Button from "../Components/Common/Button";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <Background backgroundColor={"#0d0d0d"}>
        <section className="notfound d-flex align-items-center justify-content-center">
          <div className="row text-center">
            <div className="col-12">
              <h1>
                OOPS! <br /> 404
                <br /> Page Not Found
              </h1>
              <Link to={"/"} className="d-flex justify-content-center mt-4">
                <Button text="Back To Home" />
              </Link>
            </div>
            {/* <div className="col-6">
                <img src={notfound} alt="" />
              </div> */}
          </div>
        </section>
      </Background>
    </>
  );
};

export default NotFound;
