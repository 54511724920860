import Background from "../Components/Common/Background";
import Project from "../Components/CompleteProject/Project";
import Hero from "../Components/Hero/Hero";
import Professional from "../Components/Professional/Professional";
import CompanySlider from "../Components/CompanySlider/CompanySlider";
import MarqueeSlider from "../Components/MarqueeSlider/MarqueeSlider";
import Button from "../Components/Common/Button";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Fade, Zoom, Bounce } from "react-reveal";
import "swiper/css";

const border = 'https://mysocialtheory.com/assets/images/border.svg'
const slide1Left = "https://mysocialtheory.com/assets/images/slide1_left.png";
const slide1Right = "https://mysocialtheory.com/assets/images/slide1_right.png";
const slide2Left = "https://mysocialtheory.com/assets/images/slide2_left.png";
const slide2Right = "https://mysocialtheory.com/assets/images/slide2_right.png";
const slider_left_arr = "https://mysocialtheory.com/assets/images/left_arrow.svg";
const slider_right_arr = "https://mysocialtheory.com/assets/images/right_arrow.svg";
const blog1 = 'https://mysocialtheory.com/assets/images/blog/blog1.png'
const blog2 = 'https://mysocialtheory.com/assets/images/blog/blog2.png'
const blog3 = 'https://mysocialtheory.com/assets/images/blog/blog3.png'


const Home = () => {
  return (
    <div className="overflow-hidden">
      <Background backgroundColor="#0d0d0d">
        <Hero />
        <Fade bottom>
          <Swiper
            modules={[Navigation]}
            className="slider_main"
          >
            <SwiperSlide>
              <div className="slide_main slide1 d-none d-md-block">
                <Link to="/service" className="slide_left">
                  <img src={slide1Left} alt="services" />
                </Link>
                <Link to="/about-us" className="slide_right">
                  <img src={slide1Right} alt="aboutus" />
                </Link>
              </div>
              <div className="services services_home d-flex d-md-none">
                <Link to="/service" className="wht_we_do">
                  <div className="number">
                    <img src={border} alt="border" />
                    <span>01</span>
                  </div>
                  <div className="text">
                    <h1>OUR</h1>
                    <div>
                      <span></span>
                      <span>SERVICES</span>
                      <span></span>
                    </div>
                    <p>Fostering meaningful connections, Empowering Brands, Expanding Reach: Social Theory offers tailored digital marketing solutions that drive growth, from strategic campaigns to impactful social media management.</p>
                  </div>
                </Link>
                <Link to="/about-us" className="how_we_do">
                  <div className="number">
                    <img src={border} alt="border" />
                    <span>02</span>
                  </div>
                  <div className="text">
                    <h1>ABOUT</h1>
                    <div>
                      <span></span>
                      <span>US</span>
                      <span></span>
                    </div>
                    <p>From humble beginnings to greater insights. Creativity is our North Star. Discover the journey behind Social Theory and the story that shaped us.</p>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slide_main slide2 d-none d-md-block">
                <Link to="/what-we-do" className="slide_left">
                  <img src={slide2Left} alt="" />
                </Link>
                <Link to="/how-we-do" className="slide_right">
                  <img src={slide2Right} alt="" />
                </Link>
              </div>
              <div className="services services_home d-flex d-md-none">
                <Link to="/what-we-do" className="wht_we_do">
                  <div className="number">
                    <img src={border} alt="border" />
                    <span>01</span>
                  </div>
                  <div className="text">
                    <h1>WHAT</h1>
                    <div>
                      <span></span>
                      <span>WE DO</span>
                      <span></span>
                    </div>
                    <p>We transcend conventional bounds by crafting unique digital experiences, curating thumb stopping narratives and cutting-edge strategies that sculpt an echo chamber for your brand’s voice.</p>
                  </div>
                </Link>
                <Link to="/how-we-do" className="how_we_do">
                  <div className="number">
                    <img src={border} alt="border" />
                    <span>01</span>
                  </div>
                  <div className="text">
                    <h1>HOW</h1>
                    <div>
                      <span></span>
                      <span>WE DO</span>
                      <span></span>
                    </div>
                    <p>As dreamers united, we infuse every strategy with team spirit, intertwining innovation and insight. Together, we spin visions into reality, crafting digital triumphs that define the essence of Social Theory.</p>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </Fade>
        <div className="compnay_slider">
          <CompanySlider />
        </div>
      </Background>
      <Background backgroundColor="#171119">
        <Project />
      </Background>
      <Background backgroundColor="#0d0d0d">
        <div className="marqueeslider_main">
          <MarqueeSlider />
        </div>
        <Professional />
        <Zoom>
          <div className="member_review text-center">
            <div className="container">
              <h5>What our Client say</h5>
              <div className="member_review_slider">
                <Swiper
                  slidesPerView={1}
                  modules={[Navigation]}
                  navigation={{
                    enabled: true,
                    nextEl: ".swiper_next",
                    prevEl: ".swiper_prev",
                  }}
                  loop
                >
                  <SwiperSlide>
                    <p>
                      Social Theory is one of the best in the business. Their expertise in social media marketing and digital strategy has transformed our online presence, resulting in a staggering increase in brand visibility and sales.
                    </p>
                    <h6>Walid Bakhit</h6>
                    <span>Founder, Basha Foods</span>
                  </SwiperSlide>
                  <SwiperSlide>
                    <p>
                      Social theory is a reliable company. They are very patient and always go above and beyond in their efforts to get us favourable results. They discuss and listen to all our requirements carefully and are exceptionally time bound.
                    </p>
                    <h6>Paula Lestinho</h6>
                    <span>Founder, WSJ</span>
                  </SwiperSlide>
                  <SwiperSlide>
                    <p>
                      Social Theory’s team is highly approachable and easy to work with. The website they created for me is exactly what I wanted. They delivered it before time and resolved every issue I had and pretty much any time of the day.
                    </p>
                    <h6>Navtej Bedi</h6>
                    <span>Owner, Bedi Financial Services</span>
                  </SwiperSlide>
                  <SwiperSlide>
                    <p>
                      Social Theory for us has been remarkable. Their Graphic Designs have been a game changer. We see a visible Brand differential for us in the market. Their meticulous market research gave us fresh perspectives.
                    </p>
                    <h6>Hitesh Shah</h6>
                    <span>Proprietor, K enterprises</span>
                  </SwiperSlide>
                  <SwiperSlide>
                    <p>
                      I like how flexible and patient they are. Since it’s a new company, they helped us to create a brand identity and spread awareness in the market.
                    </p>
                    <h6>Hetvi Barot</h6>
                    <span>Co-Founder, CitySlice Pizzeria</span>
                  </SwiperSlide>
                  <SwiperSlide>
                    <p>
                      Social Theory has created a really beautiful logo for our company. We looked for an old school rustic design. They delivered it exactly how we wanted it. We will continue to work with them for our upcoming website design and app!
                    </p>
                    <h6>Jason Cayetano</h6>
                    <span>Plant Manager, Western Fine Meats</span>
                  </SwiperSlide>
                </Swiper>
                <span className="swiper_prev">
                  <img src={slider_right_arr} alt="left-arrow" />
                </span>
                <span className="swiper_next">
                  <img src={slider_left_arr} alt="next-arrow" />
                </span>
              </div>
            </div>
          </div>
        </Zoom>
        <div className="marqueeslider_main">
          <MarqueeSlider />
        </div>
        <section className="latest_posts">
          <div className="px-4 px-xl-5">
            <div className="latestpost_heading d-flex flex-column flex-md-row justify-content-between align-items-center">
              <Fade left>
                <h2 className="text-center">
                  latest <span>posts</span>
                </h2>
              </Fade>
              <Fade right>
                <div>
                  <Button link="/blog" text="See all blogs" />
                </div>
              </Fade>
            </div>
            <Bounce cascade left>
              <div className="row g-4 justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="latest_posts_box">
                    <img src={blog1} alt="blog1" />
                    <h4>Marketing: An ever evolving yet a timeless skill</h4>
                    <Link to={"/blog-detail/marketing: an ever evolving yet a timeless skill"} className="d-flex align-items-center">
                      Read More
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M12.5293 11L12.5293 4L20.5293 12L12.5293 20L12.5293 13L4.5293 13L4.5293 11L12.5293 11Z" fill="url(#paint0_linear_1142_433)" />
                        <defs>
                          <linearGradient id="paint0_linear_1142_433" x1="20.9985" y1="19.1054" x2="3.04764" y2="-0.239712" gradientUnits="userSpaceOnUse">
                            <stop offset="0.307292" stop-color="#006EDA" />
                            <stop offset="0.505208" stop-color="#723582" />
                            <stop offset="0.729167" stop-color="#DB0030" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="latest_posts_box">
                    <img src={blog2} alt="blog2" />
                    <h4>Why Trendy Graphic Designs are a Game-Changer for Your Business & Social Media</h4>
                    <Link to={"blog-detail/why trendy graphic designs are a game-changer for your business & social media"} className="d-flex align-items-center">
                      Read More
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M12.5293 11L12.5293 4L20.5293 12L12.5293 20L12.5293 13L4.5293 13L4.5293 11L12.5293 11Z" fill="url(#paint0_linear_1142_433)" />
                        <defs>
                          <linearGradient id="paint0_linear_1142_433" x1="20.9985" y1="19.1054" x2="3.04764" y2="-0.239712" gradientUnits="userSpaceOnUse">
                            <stop offset="0.307292" stop-color="#006EDA" />
                            <stop offset="0.505208" stop-color="#723582" />
                            <stop offset="0.729167" stop-color="#DB0030" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="latest_posts_box">
                    <img src={blog3} alt="blog3" />
                    <h4>The Strategic Edge: 12 Reasons to Invest in a Social Media Marketer's Expertise</h4>
                    <Link to={"/blog-detail/the strategic edge: 12 reasons to invest in a social media marketer's expertise"} className="d-flex align-items-center">
                      Read More
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M12.5293 11L12.5293 4L20.5293 12L12.5293 20L12.5293 13L4.5293 13L4.5293 11L12.5293 11Z" fill="url(#paint0_linear_1142_433)" />
                        <defs>
                          <linearGradient id="paint0_linear_1142_433" x1="20.9985" y1="19.1054" x2="3.04764" y2="-0.239712" gradientUnits="userSpaceOnUse">
                            <stop offset="0.307292" stop-color="#006EDA" />
                            <stop offset="0.505208" stop-color="#723582" />
                            <stop offset="0.729167" stop-color="#DB0030" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </Bounce>
          </div>
        </section>
      </Background>
    </div >
  );
};

export default Home;
