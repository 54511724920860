import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Service from "./Pages/Service";
import Team from "./Pages/Team";
import NotFound from "./Pages/NotFound";
import TeamDetails from "./Pages/TeamDetails";
import ContactUs from "./Pages/ContactUs";
import PortfolioDetails from "./Pages/PortfolioDetails";
import Aboutus from "./Pages/Aboutus";
import Approach from "./Pages/Approach";
import Layout from "./Pages/Layout";
import Blog from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import AnimatedCursor from "react-animated-cursor"
import ServiceNew from "./Pages/ServiceNew";
import PortfolioNew from "./Pages/PortfolioNew";
import ClientDesigns from "./Pages/ClientDesigns";
import ConceptDesigns from "./Pages/ConceptDesigns";
import ClientDesignDetails from "./Pages/ClientDesignDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/theme.scss";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
// import Portfolio from "./Pages/Portfolio";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <AnimatedCursor
        showSystemCursor={true}
        trailingSpeed={6}
        innerSize={0}
        outerSize={35}
        innerScale={1}
        outerScale={2}
        outerAlpha={0.4}
        color="19, 19, 19"
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      />
      <ToastContainer limit={3} newestOnTop autoClose={3000} theme="dark" />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/service" element={<ServiceNew />} />
          <Route path="/what-we-do" element={<Service />} />
          <Route path="/how-we-do" element={<Approach />} />
          <Route path="/portfolio" element={<PortfolioNew />} />
          <Route path="/portfolio-details" element={<PortfolioDetails />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team-details/:name" element={<TeamDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail/:name" element={<BlogDetails />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/client-designs" element={<ClientDesigns />} />
          <Route path="/client-designs/:name" element={<ClientDesignDetails />} />
          <Route path="/concept-designs" element={<ConceptDesigns />} />
          <Route path="/concept-designs/:name" element={<ClientDesignDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes >
    </>
  );
}

export default App;
