// GraphicDesign
const bread = 'https://mysocialtheory.com/assets/images/concept/graphic/bread.png'
const burger = 'https://mysocialtheory.com/assets/images/concept/graphic/burger.png'
const electronics = 'https://mysocialtheory.com/assets/images/concept/graphic/electronics.png'
const fashion = 'https://mysocialtheory.com/assets/images/concept/graphic/fashion.png'
const housing = 'https://mysocialtheory.com/assets/images/concept/graphic/housing.png'
const indianResto = 'https://mysocialtheory.com/assets/images/concept/graphic/indianResto.png'
const sports = 'https://mysocialtheory.com/assets/images/concept/graphic/sports.png'

// Branding/Packging
const alchohol = 'https://mysocialtheory.com/assets/images/concept/branding-packging/alchohol.png'
const beverages = 'https://mysocialtheory.com/assets/images/concept/branding-packging/beverages.png'
const candles = 'https://mysocialtheory.com/assets/images/concept/branding-packging/candles.png'
const packaging = 'https://mysocialtheory.com/assets/images/concept/branding-packging/packaging.png'
const sticks = 'https://mysocialtheory.com/assets/images/concept/branding-packging/sticks.png'

// LogoDesign
const apparel = 'https://mysocialtheory.com/assets/images/concept/logo/apparel.png'
const furniture = 'https://mysocialtheory.com/assets/images/concept/logo/furniture.png'
const grocery = 'https://mysocialtheory.com/assets/images/concept/logo/grocery.png'
const resto = 'https://mysocialtheory.com/assets/images/concept/logo/resto.png'
const travel = 'https://mysocialtheory.com/assets/images/concept/logo/travel.png'

// UI/UX
const groceryUIUX = 'https://mysocialtheory.com/assets/images/concept/ui-ux/grocery.png'
const music = 'https://mysocialtheory.com/assets/images/concept/ui-ux/music.png'
const pizza = 'https://mysocialtheory.com/assets/images/concept/ui-ux/pizza.png'
const travelUIUX = 'https://mysocialtheory.com/assets/images/concept/ui-ux/travel.png'

// WebDesign
const headphones = 'https://mysocialtheory.com/assets/images/concept/webDesign/headphones.png'
const shoes = 'https://mysocialtheory.com/assets/images/concept/webDesign/shoes.png'


const data = {
    GraphicDesign: {
        images: [{ img: burger, link: "burger" }, { img: bread, link: "bread" }, { img: electronics, link: "electronics" }, { img: fashion, link: "fashion" }, { img: indianResto, link: "indianResto" }, { img: housing, link: "housing" }, { img: sports, link: "sports" }]
    },
    LogoDesign: {
        images: [{ img: apparel, link: "apparel" }, { img: furniture, link: "furniture" }, { img: grocery, link: "grocery" }, { img: resto, link: "resto" }, { img: travel, link: "travel" }]
    },
    WebDesign: {
        images: [{ img: shoes, link: "shoes" }, { img: headphones, link: "headphones" }]
    },
    'Branding/Packging': {
        images: [{ img: packaging, link: "packaging" }, { img: alchohol, link: "alchohol" }, { img: beverages, link: "beverages" }, { img: candles, link: "candles" }, { img: sticks, link: "sticks" }]
    },
    'UI/UX': {
        images: [{ img: music, link: "music" }, { img: groceryUIUX, link: "groceryUIUX" }, { img: travelUIUX, link: "travelUIUX" }, { img: pizza, link: "pizza" }]
    },
}

export default data