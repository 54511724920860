import Background from "../Components/Common/Background";
import BlogCard from "../Components/Common/BlogCard";
import Sidebar from "../Components/Common/Sidebar";
import Pagination from "../Components/Common/Pagination";
import { Offcanvas } from "react-bootstrap";
import { useState } from "react";
import Fade from 'react-reveal/Fade'
import data from '../Constants/BlogData'
import styles from "../assets/css/blog.module.scss";

const Blog = () => {
  const [isSidebar, setIsSidebar] = useState(false);

  return (
    <Background backgroundColor="#0d0d0d">
      <div
        className={`${styles.main} position-relative px-4 px-lg-5 d-flex flex-column flex-lg-row overflow-x-hidden`}
      >
        <Fade left>
          <div className={`${styles.sidebar} d-none d-lg-block`}>
            <Sidebar />
          </div>
        </Fade>
        <div
          className={`${styles.sidebar_open} d-flex d-lg-none`}
          onClick={() => setIsSidebar(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M12 13V20L4 12L12 4V11H20V13H12Z" fill="white" />
          </svg>
        </div>
        <Offcanvas
          show={isSidebar}
          onHide={() => setIsSidebar(false)}
          className={styles.sidebar_canvas}
        >
          <div className={`${styles.sidebar} d-block d-lg-none`}>
            <Sidebar />
          </div>
          <div
            className={`${styles.sidebar_close} position-absolute`}
            onClick={() => setIsSidebar(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M12 13V20L20 12L12 4V11H4V13H12Z" fill="white" />
            </svg>
          </div>
        </Offcanvas>
        <Fade right>
          <div className={`${styles.right_main}`}>
            <BlogCard
              img={data['marketing: an ever evolving yet a timeless skill'].bannerImg}
              type="business"
              author="Jasneet Kaur"
              date="July 10, 2023"
              commentsCount="05"
              heading="Marketing: An ever evolving yet a timeless skill"
              text="The internet is flooded with extensive amounts of information. With my knowledge and learnings, I am here to help you understand better about where to begin with marketing through this article."
              link="marketing: an ever evolving yet a timeless skill"
            />
            <BlogCard
              img={data['why trendy graphic designs are a game-changer for your business & social media'].bannerImg}
              type="business"
              author="Kaushil Shah"
              date="August 09, 2022"
              commentsCount="26"
              heading="Why Trendy Graphic Designs are a Game-Changer for Your Business & Social Media"
              text="Welcome to the world of colours, patterns, and designs! If you've ever wondered why some Instagram posts get more likes than others or why certain websites just feel more inviting, chances are, graphic design is the unsung hero behind the scenes."
              link="why trendy graphic designs are a game-changer for your business & social media"
            />
            <BlogCard
              img={data["the strategic edge: 12 reasons to invest in a social media marketer's expertise"].bannerImg}
              type="business"
              author="Abhijeet Shah"
              date="June 20, 2023"
              commentsCount="11"
              heading="The Strategic Edge: 12 Reasons to Invest in a Social Media Marketer's Expertise"
              text="In the modern battlefield of business, where attention is the most coveted currency, a social media marketer emerges as the strategic commander. Just as a skilled warrior directs their focus toward victory, a proficient social media marketer channels their expertise to elevate your brand's presence and impact. Let's delve into the pivotal reasons why collaborating with a social media marketer is an investment that can reshape your brand's trajectory."
              link="the strategic edge: 12 reasons to invest in a social media marketer's expertise"
            />
            {/* <Pagination /> */}
          </div>
        </Fade>
      </div>
    </Background>
  );
};

export default Blog;
