import { Link } from "react-router-dom";
import styles from "./Common.module.scss";

const Button = ({ text, type, link }) => {
  return (
    <Link to={link ?? "/"} type={type ?? "button"} className={`${styles.common_btn}`}>
      {text}
    </Link>
  );
};

export default Button;
