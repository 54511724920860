import styles from "../../assets/css/blog.module.scss";
import { Link } from "react-router-dom";

const fileIcon = "https://mysocialtheory.com/assets/images/file-icon.svg";
const personIcon = "https://mysocialtheory.com/assets/images/person-icon.svg";
const calendarIcon = "https://mysocialtheory.com/assets/images/calendar-icon.svg";
const chatIcon = "https://mysocialtheory.com/assets/images/chat-icon.svg";


const BlogCard = ({
  img,
  type,
  author,
  date,
  commentsCount,
  heading,
  text,
  link
}) => {
  return (
    <div>
      <div className={styles.blog_img}>
        <img className="w-100 h-auto" src={img} alt="blog-image" />
      </div>
      <div className={styles.info}>
        <div className="d-flex gap-2 gap-xl-3 align-items-center">
          <img src={fileIcon} alt="file-icon" />
          <span>{type}</span>
        </div>
        <div className="d-flex gap-2 gap-xl-3 align-items-center">
          <img src={personIcon} alt="file-icon" />
          <span>{author}</span>
        </div>
        <div className="d-flex gap-2 gap-xl-3 align-items-center">
          <img src={calendarIcon} alt="file-icon" />
          <span>{date}</span>
        </div>
        <div className="d-flex gap-2 gap-xl-3 align-items-center">
          <img src={chatIcon} alt="file-icon" />
          <span>{commentsCount}</span>
        </div>
      </div>
      <div className={styles.description}>
        <h3 className="mb-2 mb-md-4">{heading}</h3>
        <p className="mb-0">{text}</p>
        <Link to={`/blog-detail/${link}`}>
          <span>CONTINUE READING </span>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M12.5293 11L12.5293 4L20.5293 12L12.5293 20L12.5293 13L4.5293 13L4.5293 11L12.5293 11Z" fill="url(#paint0_linear_1142_433)" />
            <defs>
              <linearGradient id="paint0_linear_1142_433" x1="20.9985" y1="19.1054" x2="3.04764" y2="-0.239712" gradientUnits="userSpaceOnUse">
                <stop offset="0.307292" stop-color="#006EDA" />
                <stop offset="0.505208" stop-color="#723582" />
                <stop offset="0.729167" stop-color="#DB0030" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
