import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { Link, useLocation } from "react-router-dom";

import { FaSearch } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GrMail } from "react-icons/gr";
import { MdCall } from "react-icons/md";
import { Offcanvas } from "react-bootstrap";
import { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const logo = "https://mysocialtheory.com/assets/images/logo.png";
const menu = "https://mysocialtheory.com/assets/images/navbar_menu_icon.svg";
const close = "https://mysocialtheory.com/assets/images/close_icon.svg";
const filledArrow = "https://mysocialtheory.com/assets/images/filled_arrow.svg";
const borderedArrow = "https://mysocialtheory.com/assets/images/arrow_up.png";
const dribble = "https://mysocialtheory.com/assets/images/dribble.svg";
const behance = "https://mysocialtheory.com/assets/images/behance.svg";
const instagram = "https://mysocialtheory.com/assets/images/instagram.svg";
const facebook = "https://mysocialtheory.com/assets/images/facebook.svg";
const twitter = "https://mysocialtheory.com/assets/images/twitter.svg";
const youtube = "https://mysocialtheory.com/assets/images/youtube.svg";


gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [isNavbar, setIsNavbar] = useState(false);
  const { pathname } = useLocation();
  const navRef = useRef();

  useEffect(() => {
    setIsSidebar(false);
  }, [pathname]);

  useLayoutEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset >= navRef?.current?.clientHeight
        ? setIsNavbar(true)
        : setIsNavbar(false);
    });
  }, []);

  return (
    <>
      <nav
        style={{ background: isNavbar ? "#1c1c1c" : "transparent" }}
        ref={navRef}
        className={`${styles.nav_main} p-md-4 px-xl-5 navbar navbar-expand-lg sticky-top`}
      >
        <div className="container-fluid px-0">
          <Link className="navbar-brand me-0 py-0" to="/">
            <img src={logo} alt="logo" />
          </Link>
          <button
            className={styles.navbar_btn}
            onClick={() => setIsSidebar(true)}
          >
            <img src={menu} alt="menu-icon" />
          </button>
        </div>
      </nav>
      <Offcanvas
        show={isSidebar}
        placement="end"
        className={styles.offcanvas_main}
      >
        <div className={`${styles.canvas_header_main} row mx-0`}>
          <div className="d-flex align-items-center p-0 pe-md-0 col-8 px-md-5 py-md-4">
            <Link className={styles.offcanvas_logo} to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="col-4 p-0 px-md-5 py-md-4 text-end">
            <button
              className={styles.navbar_btn}
              onClick={() => setIsSidebar(false)}
            >
              <img src={close} alt="menu-icon" />
            </button>
          </div>
        </div>
        <div className={`${styles.row_main} h-100 row mx-0`}>
          <div className={`${styles.offcanvas_left} px-0 col-lg-8`}>
            <div className={`${styles.offcanvas_nav_main}`}>
              <ul className="mb-0">
                <li
                  className={
                    pathname === "/" && styles.offcanvas_navlink_active
                  }
                >
                  <Link to="/">
                    <div>Home</div>
                    <div>
                      <div>
                        <img
                          src={pathname === "/" ? filledArrow : borderedArrow}
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/about-us" && styles.offcanvas_navlink_active
                  }
                >
                  <Link to="/about-us">
                    <div>About Us</div>
                    <div>
                      <div>
                        <img
                          src={
                            pathname === "/about-us"
                              ? filledArrow
                              : borderedArrow
                          }
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/service" && styles.offcanvas_navlink_active
                  }
                >
                  <Link to="/service">
                    <div>Service</div>
                    <div>
                      <div>
                        <img
                          src={
                            pathname === "/service"
                              ? filledArrow
                              : borderedArrow
                          }
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/portfolio" && styles.offcanvas_navlink_active
                  }
                >
                  <Link to="/portfolio">
                    <div>Portfolio</div>
                    <div>
                      <div>
                        <img
                          src={
                            pathname === "/portfolio"
                              ? filledArrow
                              : borderedArrow
                          }
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/team" && styles.offcanvas_navlink_active
                  }
                >
                  <Link to="/team">
                    <div>Team</div>
                    <div>
                      <div>
                        <img
                          src={
                            pathname === "/team" ? filledArrow : borderedArrow
                          }
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li
                  className={
                    pathname === "/blog" && styles.offcanvas_navlink_active
                  }
                >
                  <Link to="/blog">
                    <div>Blog</div>
                    <div>
                      <div>
                        <img
                          src={
                            pathname === "/blog" ? filledArrow : borderedArrow
                          }
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={`${styles.offcanvas_right} col-lg-4 px-xl-5`}>
            <div className={styles.search_main}>
              <input
                className="w-100"
                type="text"
                placeholder="Search keyword"
              />
              <FaSearch />
            </div>
            <div className={styles.contactus_box_info}>
              <Link to="/contact-us" className={styles.contactus_heading}>
                <h3>Get In Touch</h3>
                <div>
                  <img src={borderedArrow} alt="arrow-icon" />
                </div>
              </Link>
              <ul>
                <li>
                  <div className="d-flex align-items-start">
                    <MdCall className={styles.company_socical_ico} />
                    <div>
                      <h6>Phone</h6>
                      <span>+1 (250) 879 2165</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-start">
                    <GrMail className={styles.company_socical_ico} />
                    <div>
                      <h6>Email</h6>
                      <span>info@mysocialtheory.com</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex align-items-start">
                    <FaLocationDot className={styles.company_socical_ico} />
                    <div>
                      <h6>Location</h6>
                      <span>73 Skyview Point Crescent NE,</span>
                      <br />
                      <span>Calgary AB T3N 1S9</span>
                      <br />
                      <span>Monday to Saturday</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className={styles.followus_main}>
              <h3>Follow Us</h3>
              <div className={styles.icons}>
                <a href="https://www.instagram.com/socialtheories/" target="_new">
                  <img src={instagram} alt="instagram-icon" />
                </a>
                <a href="https://www.facebook.com/mysocialtheory" target="_new">
                  <img src={facebook} alt="facebook-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default Header;
