import { Link } from 'react-router-dom'
import styles from '../assets/css/clientDesign.module.scss'

const bashaFood = 'https://mysocialtheory.com/assets/images/client/bashaFoods/bashaFoods.png'
const bashaVape = 'https://mysocialtheory.com/assets/images/client/bashaVapes/bashaVapes.png'
const wsj = 'https://mysocialtheory.com/assets/images/client/wsj.png'
const Kenterprises = 'https://mysocialtheory.com/assets/images/client/Kenterprises.png'
const freshSlice = 'https://mysocialtheory.com/assets/images/client/freshSlice.png'
const citySlice = 'https://mysocialtheory.com/assets/images/client/citySlice.png'
const bediFinance = 'https://mysocialtheory.com/assets/images/client/bediFinance.png'
const westernFineMeats = 'https://mysocialtheory.com/assets/images/client/westernFineMeats.png'
const trville = 'https://mysocialtheory.com/assets/images/client/trville.png'

const ClientDesigns = () => {
  return (
    <div className={`${styles.main} px-4`}>
      <div className='d-flex gap-3 gap-lg-5 flex-column flex-md-row'>
        <Link to="basha-food" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={bashaFood} alt="bashaFood" />
          <div className={styles.overlay}></div>
        </Link>
        <Link to="basha-vapes" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={bashaVape} alt="bashaVapes" />
          <div className={styles.overlay}></div>
        </Link>
      </div>
      <div className='d-flex gap-3 gap-lg-5 flex-column flex-md-row'>
        <Link to="wsj" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={wsj} alt="wsj" />
          <div className={styles.overlay}></div>
        </Link>
        <Link to="k-enterprises" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={Kenterprises} alt="Kenterprises" />
          <div className={styles.overlay}></div>
        </Link>
      </div>
      <div className='d-flex gap-3 gap-lg-5 flex-column flex-md-row'>
        <Link to="fresh-slice-kamloops" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={freshSlice} alt="freshSlice" />
          <div className={styles.overlay}></div>
        </Link>
        <Link to="city-slice-pizzeria" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={citySlice} alt="citySlice" />
          <div className={styles.overlay}></div>
        </Link>
      </div>
      <div className='d-flex gap-3 gap-lg-5 flex-column flex-md-row'>
        <Link to="bedi-finance" className='d-flex position-relative'>
          <img className='w-100 h-auto rounded-5' src={bediFinance} alt="bediFinance" />
          <div className={styles.overlay}></div>
        </Link>
        <div className={'d-flex position-relative' + " " + styles.coming_soon_parent}>
          <img className='w-100 h-auto rounded-5' src={westernFineMeats} alt="westernFineMeats" />
          <div className={styles.overlay}></div>
          <div className={styles.coming_soon}>
            <span>Coming soon</span>
          </div>
        </div>
      </div>
      <div className='d-flex gap-3 gap-lg-5 flex-column flex-md-row'>
        <div className={"col-12 col-md-7 d-flex position-relative" + " " + styles.coming_soon_parent}>
          <img className='w-100 h-auto rounded-5' src={trville} alt="trville" />
          <div className={styles.overlay}></div>
          <div className={styles.coming_soon + " rounded-5"}>
            <span>Coming soon</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDesigns