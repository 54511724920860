import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { BiLogoTelegram } from "react-icons/bi";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import Clock from "react-clock";
import { Form } from "react-bootstrap";
import "react-clock/dist/Clock.css";
import moment from "moment-timezone";

const logo = "https://mysocialtheory.com/assets/images/logo.png";
const cross_arrow_up = "https://mysocialtheory.com/assets/images/cross_arrow_up.png";


const Footer = () => {
  const [value, setValue] = useState(new Date(moment().tz('Canada/Mountain').format('YYYY-MM-DD HH:mm:ss')));

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date(moment().tz('Canada/Mountain').format('YYYY-MM-DD HH:mm:ss'))), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <footer className={styles.footer}>
        <div className="container">
          <Link to={"/contact-us"} className={styles.get_in_touch + " d-flex"}>
            <h1 className="mb-0">GET IN TOUCH </h1>
            <div className={styles.arrowImg}>
              <img className="w-100 h-auto" src={cross_arrow_up} alt="" />
            </div>
          </Link>
          <div className="row g-3 m-2">
            <div className={`${styles.col1 + " " + styles.footer_list} col-12 col-md-6 col-lg-3 d-flex flex-column justify-content-between`}>
              <div className={styles.footer_list}>
                <img src={logo} alt="" />
              </div>
              <div className={styles.clock}>
                <Clock
                  value={value}
                  className={styles.watch}
                  hourHandLength={40}
                  minuteHandLength={50}
                  secondHandLength={60}
                  hourMarksLength={0}
                  hourHandOppositeLength={0}
                  minuteHandOppositeLength={0}
                  secondHandOppositeLength={0}
                  size={120}
                />
                <h6 className={styles.calgary_clock}>Calgary clock</h6>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className={styles.footer_list}>
                <h4>AREAS WE SERVE</h4>
                <ul>
                  <li>
                    <Link>Calgary</Link>
                  </li>
                  <li>
                    <Link>Edmonton</Link>
                  </li>
                  <li>
                    <Link>Vancouver</Link>
                  </li>
                  <li>
                    <Link>Toronto</Link>
                  </li>
                  <li>
                    <Link>Montreal</Link>
                  </li>
                  <li>
                    <Link>Winnipeg</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className={styles.footer_list}>
                <h4>CONTACT INFO</h4>
                <ul>
                  <li>
                    <p>73 Skyview Point Crescent NE, Calgary AB T3N 1S9</p>
                  </li>
                  <li>
                    <p>+1 (250) 879 2165</p>
                  </li>
                  <li>
                    <Link to={"mailto:info@mysocialtheory.com"}>
                      info@mysocialtheory.com
                    </Link>
                  </li>
                  <li>
                    <p>Monday to Saturday</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className={styles.footer_list}>
                <h4>NEWSLETTER</h4>
                <Form>
                  <input type="email" placeholder="Enter mail" required />
                  <button>
                    SUBSCRIBE
                    <BiLogoTelegram className={styles.subscribe_icon} />
                  </button>
                </Form>
              </div>
            </div>
          </div>
          <div className={styles.copyright_sec}>
            <div className="row gap-3 gap-md-0">
              <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
                <p className="mb-0">© 2022 All Right Recived - Social Theory</p>
              </div>
              <div className={styles.social_icons + " ms-auto col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end"}>
                <a href="https://www.facebook.com/mysocialtheory" target="_new">
                  <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/socialtheories/" target="_new">
                  <FaInstagram />
                </a>
                <a href="https://www.linkedin.com/company/mysocialtheory" target="_new">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
