import { VscCheck } from "react-icons/vsc";
import Background from "../Components/Common/Background";
import Comment from "../Components/Common/Comment";
import { FaYoutube, FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import styles from "../assets/css/portfoliodetails.module.scss";
import ContactForm from "../Components/Common/ContactForm";
import { Fade, Bounce } from 'react-reveal'

const nextArrow = "https://mysocialtheory.com/assets/images/left_arrow.svg";
const prevArrow = "https://mysocialtheory.com/assets/images/right_arrow.svg";
const portfolioCard1 = "https://mysocialtheory.com/assets/images/portfolioCard1.png";
const portfolioCard2 = "https://mysocialtheory.com/assets/images/portfolioCard2.png";
const portfolioCard3 = "https://mysocialtheory.com/assets/images/portfolioCard3.png";
const userImg = "https://mysocialtheory.com/assets/images/userimage.png";
const arrow = "https://mysocialtheory.com/assets/images/arrow_up.png";
const portfolio_dtl_banner = "https://mysocialtheory.com/assets/images/portfolio_dtl_banner.png";



const PortfolioDetails = () => {
  return (
    <Background backgroundColor="#0d0d0d">
      <div className={styles.spacer + " overflow-x-hidden"}>
        <section className="portfolio_details">
          <div className="container">
            <Fade top>
              <div className="portfolio_banner position-relative">
                <img src={portfolio_dtl_banner} alt="portfolio_dtl_banner" />
              </div>
            </Fade>
            <div className="row">
              <Fade left>
                <div className="col-12 col-lg-8">
                  <div className="portfolio_box_info">
                    <h4>Portrait Man Digital Art BASKCATE BALL ART</h4>
                    <p>
                      UX design refers to the term “user experience design”, while
                      UI stands for “user interface design”. Both elements are
                      crucial to a product and work closely together. But despite
                      their professional relationship, the roles themselves are
                      quite different, referring to very different aspects of the
                      product development process and the design discipline.
                    </p>
                    <p>
                      In relation to websites and apps, UI design considers the
                      look, feel, and interactivity of the product. It’s all about
                      making sure that the user interface of a product is as
                      intuitive as possible, and that means carefully considering
                      each and every visual,
                    </p>
                  </div>
                  <div className="portfolio_box_info">
                    <h4>Problems</h4>
                    <p>
                      The challenges run from mundane to weird, and old-fashioned
                      to futuristic, but most are kept as open as possible. It’s a
                      good idea to limit yourself by imposing some assumptions,
                      constraints, and a platform (mobile / desktop / tablet etc).
                      If working in pairs, one person could pick a problem, and
                      the partner could refine it. So choose one of the following,
                      decide on a mobile or desktop solution, and then keep asking
                      questions.
                    </p>
                  </div>

                  <div className="portfolio_box_info">
                    <h4>Solutions</h4>
                    <div className="portfolio_solution_list">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            Full business control
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            User dashboard & analytics
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            Custom reporting
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            24/7 priority support
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            User dashboard & analytics
                          </p>
                        </div>
                        <div className="col-12 col-md-6">
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            Regular update monitoring
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            User dashboard & analytics
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            Regular update monitoring
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            Full business control
                          </p>
                          <p>
                            <VscCheck size={24} className="check_icon" />
                            Custom reporting
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio_box_info">
                    <h4>Results</h4>
                    <p className="mb-0">
                      Many UX designers, developers, and entrepreneurs take pride
                      in how hard it was to solve hard challenges. I don’t. I hope
                      to convince you not to either. I’m known for enjoying the
                      process of solving impossible problems, but the way I do it
                      is not obvious. I didn’t invent this method. I learned it
                      first-hand from a master.
                    </p>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className="col-12 col-lg-4">
                  <div className="information_box">
                    <h4>Information</h4>
                    <div className="row">
                      <div className="col-4">
                        <p>client : </p>
                        <p>category : </p>
                        <p>duration : </p>
                        <p>Website : </p>
                        <p>Tags : </p>
                      </div>
                      <div className="col-6">
                        <p>Orixy Theme</p>
                        <p>UI/UX Design</p>
                        <p>3 Months</p>
                        <p>Orixy@mail.com</p>
                        <p>Branding, profolio</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <div
              className={`${styles.tags_links} mt-4 mt-lg-0 row mx-0 gap-3 gap-md-0`}
            >
              <Bounce left cascade>
                <div className="col-md-6 px-0 d-flex gap-2 gap-md-3 align-items-center justify-content-center justify-content-md-start flex-wrap flex-md-nowrap">
                  {["design", "technology", "wordpress"].map((tag, index) => (
                    <div key={index} className={styles.tag}>
                      {tag}
                    </div>
                  ))}
                </div>
              </Bounce>
              <Bounce right cascade>
                <div className="col-md-6 px-0 d-flex gap-2 gap-md-3 align-items-center justify-content-center justify-content-md-end flex-wrap flex-md-nowrap">
                  <div className={styles.link}>
                    <FaFacebookF size={16} />
                  </div>
                  <div className={styles.link}>
                    <FaTwitter size={16} />
                  </div>
                  <div className={styles.link}>
                    <FaInstagram size={16} />
                  </div>
                  <div className={styles.link}>
                    <FaYoutube size={16} />
                  </div>
                </div>
              </Bounce>
            </div>
            <hr className="my-5" />
            <div className="d-flex justify-content-between align-items-center">
              <Fade left>
                <div
                  className={`${styles.arrow} d-flex align-items-center gap-3`}
                >
                  <img src={prevArrow} alt="previous" />
                  <span className={styles.arrow_txt}>previous</span>
                </div>
              </Fade>
              <Fade right>
                <div>
                  <span className={styles.arrow_txt}>next</span>
                  <img src={nextArrow} alt="next" />
                </div>
              </Fade>
            </div>
          </div>
        </section>
        <div className={`${styles.related_projects} container`}>
          <h4 >Related Projects</h4>
          <Bounce left cascade>
            <div className="d-flex justify-content-between flex-column flex-md-row gap-4">
              {[portfolioCard1, portfolioCard2, portfolioCard3].map(
                (img, index) => (
                  <div key={index} className={styles.project}>
                    <img className="w-100 h-auto" src={img} alt="project-img" />
                    <div className={styles.arrow}>
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>
                )
              )}
            </div>
          </Bounce>
        </div>
        {/* <div className={`${styles.comments} container`}>
          <h4 >Comments (02)</h4>
          <div className="d-flex flex-column">
            <Comment
              image={userImg}
              name="Benjamin"
              date="July 28, 2022"
              text="Many UX designers, developers, and entrepreneurs take pride in how hard it was to solve hard
             challenges. I don’t. I hope to convince you not to either. I’m known for enjoying the process of solving
             impossible problems, I learned it first-hand from a master."
            />
            <Comment
              isSubComment
              image={userImg}
              name="Benjamin"
              date="July 28, 2022"
              text="Many UX designers, developers, and entrepreneurs take pride in how hard it was to solve hard
             challenges. I don’t. I hope to convince you not to either. I’m known for enjoying the process of solving
             impossible problems, I learned it first-hand from a master."
            />
          </div>
        </div> */}
        <ContactForm
          heading="Leave a Reply"
          text="Your email address will not be published. Required fields are marked *"
        />
      </div >
    </Background >
  );
};

export default PortfolioDetails;
