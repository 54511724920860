import { CHILD, CONTAINER } from "../../Constants/FramerVariant";
import styles from "./Hero.module.scss";
import { motion } from "framer-motion";

const Tab2 = ({ activeTab }) => {
  return (
    <div className={styles.heading}>
      <h1
        key={activeTab}
        className="text-center"
      >
        <span className={styles.blur}>DARE</span> <span className={styles.blur}>TO</span> <span className={styles.blur}>BE</span> <span className={styles.gradient + " " + styles.tab2gradient}>DIFFERENT</span>
      </h1>
      <motion.p
        variants={CONTAINER}
        initial="hidden"
        animate="visible"
        className={`${styles.tab2_para} d-flex flex-wrap justify-content-center gap-1`}
      >
        {"In a market such as the ones we live in, you are supposed to go beyond limits! We go beyond mere clicks, harnessing the transformative power of data-driven strategies and innovative marketing techniques to turn every interaction into opportunities."
          .split(" ")
          .map((word, index) => (
            <motion.span key={index} variants={CHILD}>
              {word}
            </motion.span>
          ))}
      </motion.p>
    </div>
  );
};

export default Tab2;
