const blog1 = 'https://mysocialtheory.com/assets/images/blog/blog1.png'
const blog2 = 'https://mysocialtheory.com/assets/images/blog/blog2.png'
const blog3 = 'https://mysocialtheory.com/assets/images/blog/blog3.png'

const blogData = {
    "marketing: an ever evolving yet a timeless skill": {
        bannerImg: blog1,
        type: "business",
        author: "Jasneet Kaur",
        date: "JULY 7, 2023",
        totalComments: "05"
    },
    "why trendy graphic designs are a game-changer for your business & social media": {
        bannerImg: blog2,
        type: "business",
        author: "Kaushil Shah",
        date: "AUGUST 9, 2023",
        totalComments: "26"
    },
    "the strategic edge: 12 reasons to invest in a social media marketer's expertise": {
        bannerImg: blog3,
        type: "business",
        author: "Abhijeet Shah",
        date: "JUNE 20, 2023",
        totalComments: "11"
    },
}

export default blogData