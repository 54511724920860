const Translate3d = (elements) => {
    const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");
    const isTouchDevice = 'ontouchstart' in window

    if (isTouchDevice) return
    
    else {
        const handlHoverOver = (e) => {
            const amountMovedX = (e.clientX * -0.3 / 10);
            const amountMovedY = (e.clientY * -0.3 / 10);
            e.currentTarget.style.transition = `all 0.3s linear`
            return e.currentTarget.style.transform = 'translate(' + amountMovedX + 'px,' + amountMovedY + 'px)'
        }

        const resetStyle = (e) => {
            return e.currentTarget.style.transform = 'none'
        }

        if (!motionMatchMedia.matches) {
            elements.forEach(e => e.addEventListener("mousemove", handlHoverOver))
            elements.forEach(e => e.addEventListener("mouseleave", resetStyle))
        }

    }
}

export default Translate3d